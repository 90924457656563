import EventDispatcher from "../common/EventDispatcher";
import {
    gsap,
    Cubic,
    Power1,
    Elastic
} from 'gsap';

export default class CanInfo {
    // Constructor takes a scene and camera as parameters
    constructor(scene, camera) {
        this.scene = scene;
        this.camera = camera;
    }
    init() {
        // Getting the event dispatcher instance
        this.events = EventDispatcher.getObj();
        this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));

        this.showTO= null;
        this.canInfoIndex= -1;
        this.lastCanKey= null;

        this.animatePink();
        this.animateRed();
        this.animateGreen();
        this.animateBlue();
    }
    onEvents(data){
        switch(data['message']['event_type']){
            case "show_can_info_red":
                this.showCanInfo('red');
                break;
            case 'hide_all':
                this.hideAll(data['message']['data']&&data['message']['data']['byForce']||null, data['message']['data']&&data['message']['data']['lastCanKey']||null);
                break;
            case "update":
                this.update(data['message']['data']);
                break;
            case "reset_can_index":
                this.resetCanIndex();
                break;
            case "update_can_info":
                this.updateCanInfo(data['message']['data']);
                break;
        }
    }
    resetCanIndex(){
        if(this.canInfoIndex == -1) return false;
        this.canInfoIndex= -1;
    }
    hideAll(byForce= false, lastCanKey=null){
        // return false;
        if(this.lastCanKey == null && !byForce) return false;
        if(byForce){
            this.lastCanKey= lastCanKey
            this.events.dispatchEvent({
                type: "GAMESCENE_EVENTS",
                message: {
                    "event_type": "reveal_new_can",
                    data: {
                    }
                }
            });
        }
        console.log(byForce,' byForce')
        document.querySelectorAll(".ingredients_popup .container div").forEach((item) => {
            item.classList.remove('blue');
            item.classList.remove('pink');
            item.classList.remove('red');
            item.classList.remove('green');

            document.querySelector(`.feature_info .feature`).classList.remove('blue');
            document.querySelector(`.feature_info .feature`).classList.remove('pink');
            document.querySelector(`.feature_info .feature`).classList.remove('green');
            document.querySelector(`.feature_info .feature`).classList.remove('red');

            document.querySelector(`.feature_info .arrow img.blue`).classList.remove('active');
            document.querySelector(`.feature_info .arrow img.red`).classList.remove('active');
            document.querySelector(`.feature_info .arrow img.green`).classList.remove('active');
            document.querySelector(`.feature_info .arrow img.pink`).classList.remove('active');

        })
        this.showTO && clearTimeout(this.showTO);
        document.querySelector(".ingredients_popup").classList.remove('active');
        console.log(this.lastCanKey,'[lastCanKey]')
        document.querySelector(`.feature_sec .left .${this.lastCanKey}`).classList.remove("active2");
        document.querySelector(`.feature_sec .right .${this.lastCanKey}`).classList.remove("active2");
        document.querySelector(`.feature_info`).classList.remove("active2");
        this.showTO= setTimeout(function(lastCanKey){
            console.log(lastCanKey,'lastCanKey>>>>')
            document.querySelector(`.feature_info`).classList.remove("active");
            document.querySelector(`.feature_sec .left .${lastCanKey}`).classList.remove("active");
            document.querySelector(`.feature_sec .right .${lastCanKey}`).classList.remove("active");
        }.bind(this, this.lastCanKey), 200);

        this.lastCanKey= null;
    }
    showCanInfo(canKey){
        // alert("showCanInfo")
        this.showTO && clearTimeout(this.showTO);
        ['red','blue','green','pink'].forEach((colorKey) => {
            this[`showTO${colorKey}`] && clearTimeout(this[`showTO${colorKey}`]);
            if(colorKey!=canKey){
                document.querySelector(`.feature_sec .left .${colorKey}`).classList.remove("active2");
                document.querySelector(`.feature_sec .right .${colorKey}`).classList.remove("active2");
                // document.querySelector(`.feature_info`).classList.remove("active2");
                document.querySelector(`.feature_info .feature`).classList.remove(colorKey);
                document.querySelector(`.feature_info .arrow img.${colorKey}`).classList.remove('active');
                this[`showTO${colorKey}`]= setTimeout(function(colorKey){
                    document.querySelector(`.feature_sec .left .${colorKey}`).classList.remove("active");
                    document.querySelector(`.feature_sec .right .${colorKey}`).classList.remove("active");
                    // document.querySelector(`.feature_info`).classList.remove("active");
                }.bind(this, colorKey), 200);
            }
          
        })
        if(document.querySelector(`.feature_sec .left .${canKey}`)){
            document.querySelector(`.feature_sec .left .${canKey}`).classList.add("active");
            document.querySelector(`.feature_sec .right .${canKey}`).classList.add("active");
            document.querySelector(`.feature_info`).classList.add("active");
            document.querySelector(`.feature_info .feature`).classList.add(canKey);
            document.querySelector(`.feature_info .index`).innerHTML=`${this.canInfoIndex+1}/4`;
            document.querySelector(`.feature_info .feature`).innerHTML= ['GUARANÃ FIZZ', 'BERRY CRUSH', 'SANDÍA BITE', 'STRAWBERRY SAVAGE'][this.canInfoIndex];
            document.querySelector(`.feature_info .arrow img.${canKey}`).classList.add('active');
            this.showTO= setTimeout(function(canKey){
                document.querySelector(`.feature_sec .left .${canKey}`).classList.add("active2");
                document.querySelector(`.feature_sec .right .${canKey}`).classList.add("active2");
                document.querySelector(`.feature_info`).classList.add("active2");
            }.bind(this, canKey), 200);
        }
        console.log(this.lastCanKey,'lastCanKey')
        /* if(this.lastCanKey && this.lastCanKey !== canKey && this.lastCanKey!=null){

            document.querySelector(`.feature_sec .left .${this.lastCanKey}`).classList.remove("active2");
            document.querySelector(`.feature_sec .right .${this.lastCanKey}`).classList.remove("active2");
            // document.querySelector(`.feature_info`).classList.remove("active2");
            document.querySelector(`.feature_info .feature`).classList.remove(this.lastCanKey);
            document.querySelector(`.feature_info .arrow img.${this.lastCanKey}`).classList.remove('active');
            this.showTO= setTimeout(function(lastCanKey){
                document.querySelector(`.feature_sec .left .${lastCanKey}`).classList.remove("active");
                document.querySelector(`.feature_sec .right .${lastCanKey}`).classList.remove("active");
                // document.querySelector(`.feature_info`).classList.remove("active");
            }.bind(this, this.lastCanKey), 200);
        } */
        
        document.querySelector(".ingredients_popup").classList.remove('blue');
        document.querySelector(".ingredients_popup").classList.remove('pink');
        document.querySelector(".ingredients_popup").classList.remove('red');
        document.querySelector(".ingredients_popup").classList.remove('green');
        document.querySelector(".ingredients_popup").classList.add(canKey);
        document.querySelectorAll(".ingredients_popup .container div").forEach((item) => {
            item.classList.remove('blue');
            item.classList.remove('pink');
            item.classList.remove('red');
            item.classList.remove('green');
            item.classList.add(canKey);
        })
        
        /* if(canKey == 'red'){
            this.animateRed();
        }
        if(canKey == 'blue'){
            this.animateBlue();
        } */
        this.events.dispatchEvent({
            type: "GAMESCENE_EVENTS",
            message: {
                "event_type": "add_lightning",
                data: {
                    'canKey': canKey
                }
            }
        });

        this.lastCanKey= canKey;
    }
    updateCanInfo(data){
       
        if(data['coverIndex'] === this.canInfoIndex) return false;

        console.log(data['coverIndex'],'canInfoIndex',this.canInfoIndex)
        this.canInfoIndex= data['coverIndex'];
        switch(this.canInfoIndex){
            case 0:
                this.showCanInfo('red');
                break;
            case 1:
                this.showCanInfo('blue');
                break;
            case 2:
                this.showCanInfo('green');
                break;
            case 3:
                this.showCanInfo('pink');
                break;
        }
        // this.showCanInfo()

    }
    animatePink(){
        gsap.to('.feature_sec .left .pink .strawberry1_left', {
            duration: 3.3,
            delay: 0,
            y: `+=8`,
            x: `+=8`,
            rotation: -10,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .pink .strawberry2_left', {
            duration: 3,
            delay: 0,
            y: `-=8`,
            x: `+=0`,
            rotation: -10,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .pink .strawberry3_left', {
            duration: 4,
            delay: 0,
            y: `-=8`,
            x: `+=8`,
            rotation: 30,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .pink .strawberry4_left', {
            duration: 3.2,
            delay: 0,
            y: `-=8`,
            x: `-=8`,
            rotation: 10,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .pink .strawberry5_left', {
            duration: 3.2,
            delay: 0,
            y: `-=8`,
            x: `-=0`,
            rotation: 10,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });

        gsap.to('.feature_sec .right .pink .strawberry1_right', {
            duration: 3.2,
            delay: 0,
            y: `-=8`,
            x: `-=0`,
            rotation: 30,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .pink .strawberry2_right', {
            duration: 4.2,
            delay: 0,
            y: `-=8`,
            x: `-=10`,
            rotation: 20,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .pink .strawberry3_right', {
            duration: 3.9,
            delay: 0,
            y: `+=8`,
            x: `-=10`,
            rotation: 20,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .pink .strawberry4_right', {
            duration: 4.5,
            delay: 0,
            y: `-=10`,
            x: `-=6`,
            rotation: 0,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .pink .strawberry8_right', {
            duration: 4.5,
            delay: 0,
            y: `-=10`,
            x: `-=8`,
            rotation: 0,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        
    }
    animateGreen(){
        gsap.to('.feature_sec .left .green .sandia1_left', {
            duration: 3,
            delay: 0,
            y: `+=8`,
            x: `+=8`,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .green .sandia2_left', {
            duration: 4,
            delay: 0,
            y: `+=4`,
            x: `+=8`,
            rotation:15,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .green .sandia3_left', {
            duration: 3.5,
            delay: 0,
            y: `+=4`,
            x: `-=8`,
            rotation: 10,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .green .sandia4_left', {
            duration: 3,
            delay: 0,
            y: `+=4`,
            x: `-=8`,
            rotation: -10,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .green .sandia5_left', {
            duration: 5,
            delay: 0,
            y: `+=6`,
            x: `-=10`,
            rotation: -65,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .green .sandia6_left', {
            duration: 4.5,
            delay: 0,
            y: `-=6`,
            x: `-=8`,
            rotation: -40,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .green .sandia1_right', {
            duration: 4.5,
            delay: 0,
            y: `-=6`,
            x: `-=8`,
            rotation: -20,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .green .sandia2_right', {
            duration: 4.25,
            delay: 0,
            y: `+=6`,
            x: `+=4`,
            rotation: -10,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .green .sandia3_right', {
            duration: 4.25,
            delay: 0,
            y: `+=6`,
            x: `+=4`,
            rotation: -10,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .green .sandia4_right', {
            duration: 4.25,
            delay: 0,
            y: `+=10`,
            x: `+=8`,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .green .sandia5_right', {
            duration: 3.25,
            delay: 0,
            y: `+=5`,
            x: `+=8`,
            rotation:10,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
    }
    animateBlue(){
        gsap.to('.feature_sec .left .blue .berry1_left', {
            duration: 3,
            delay: 0,
            y: `+=8`,
            x: `+=8`,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .blue .berry2_left', {
            duration: 3,
            delay: 0,
            y: `+=4`,
            x: `-=10`,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .blue .berry3_left', {
            duration: 2,
            delay: 0,
            y: `-=4`,
            x: `+=5`,
            rotation: 10,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .blue .berry1_right', {
            duration: 3,
            delay: 0,
            y: `-=10`,
            x: `+=5`,
            rotation: 10,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .blue .berry2_right', {
            duration: 3.4,
            delay: 0,
            y: `+=10`,
            x: `-=5`,
            rotation: 10,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .blue .berry3_right', {
            duration: 3.4,
            delay: 0,
            y: `+=10`,
            x: `-=10`,
            rotation: -10,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .blue .berry4_right', {
            duration: 3,
            delay: 0,
            y: `+=8`,
            x: `-=6`,
            rotation: 15,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
    }
    animateRed(){
        /* Red Left Elements. */
        gsap.to('.feature_sec .left .red .flower1_left', {
            duration: 3,
            delay: 0,
            y: `+=8`,
            x: `+=8`,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .red .leaf1_left', {
            duration: 4,
            delay: 0,
            y: `-=8`,
            x: `+=8`,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .red .thunder1_left', {
            duration: 5,
            delay: 0,
            y: `+=8`,
            x: `+=0`,
            rotation: '+=6',
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .red .thunder2_left', {
            duration: 4.5,
            delay: 0,
            y: `+=8`,
            x: `+=10`,
            rotation: '-=5',
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .red .flower2_left', {
            duration: 3.5,
            delay: 0,
            y: `+=6`,
            x: `-=16`,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .left .red .leaf2_left', {
            duration: 3.2,
            delay: 0,
            y: `+=10`,
            x: `-=10`,
            rotation: '-=10',
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });



        /*  Red Right Elements. */
        gsap.to('.feature_sec .right .red .leaf1_right', {
            duration: 3.2,
            delay: 0,
            y: `+=10`,
            x: `-=10`,
            rotation: '-=4',
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .red .thunder1_right', {
            duration: 4.2,
            delay: 0,
            y: `-=10`,
            x: `+=6`,
            rotation: '-=5',
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .red .leaf2_right', {
            duration: 6,
            delay: 0,
            y: `-=4`,
            x: `+=6`,
            rotation: '+=10',
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .red .leaf3_right', {
            duration: 4,
            delay: 0,
            y: `-=10`,
            x: `+=12`,
            rotation: '-=10',
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to('.feature_sec .right .red .flower1_right', {
            duration: 3,
            delay: 0,
            y: `-=10`,
            x: `+=12`,
            rotation: '-=3',
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });

    }
    update(){

    }
}