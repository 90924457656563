// Import the THREE library to access the EventDispatcher class
import * as THREE from 'three';

// Initialize a variable to store a singleton instance of the EventDispatcher
let Events = null;

// Create a new class named EventDispatcher that extends the THREE.EventDispatcher class
export default class EventDispatcher extends THREE.EventDispatcher {
    // Constructor for the class
    constructor() {
        // Call the constructor of the parent class (THREE.EventDispatcher)
        super();
    }

    // Static method to get a singleton instance of the EventDispatcher
    static getObj() {
        // Check if the Events variable is null (no instance created yet)
        if (Events === null) {
            // Create a new instance of EventDispatcher if it doesn't exist
            Events = new EventDispatcher();
        }
        // Return the singleton instance
        return Events;
    }

    // Static method to reset and remove the singleton instance of the EventDispatcher
    static kill() {
        // Set the Events variable to null, effectively destroying the singleton instance
        Events = null;
    }
}
