// Importing necessary modules from the 'three' library and other dependencies
import * as THREE from 'three'
import EventDispatcher from '../common/EventDispatcher'
import { Global } from '../common/global';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

// Constants for asset types
const ASSET_TYPES = {
    TEXTURES: 'textures',
    CUBEMAP: 'cubemap',
    GLBS: 'glbs',
};

// Constants for event types
const EVENT_TYPES = {
    LOAD_COMPLETE: 'load_complete',
    ADD_SKY: 'add_sky',
};

// AssetLoader class definition
export default class AssetLoader {
    // Constructor takes a scene as a parameter
    constructor(scene) {
        this.scene = scene;
    }

    // Initialization method for setting up loaders and loading manager
    init() {
        // Getting the event dispatcher instance
        this.events = EventDispatcher.getObj();
        this.baseURL = '';  // Set the base URL for asset loading (if applicable)
        
        // Setting up the LoadingManager with callbacks for completion and error handling
        this.loadingMgr = new THREE.LoadingManager(
            // On load complete callback
            () => {
                // Dispatching events indicating that loading is complete
                this.events.dispatchEvent({ type: "LOADER_EVENTS", message: { "event_type": EVENT_TYPES.LOAD_COMPLETE, data: {} } });
                this.events.dispatchEvent({ type: "GAMESCENE_EVENTS", message: { "event_type": EVENT_TYPES.ADD_SKY, data: {} } });
            },
            // On progress callback (not utilized in this case)
            () => {},
            // On error callback (not utilized in this case)
            () => {}
        );

        // Setting up various loaders with the loading manager
        this.textureLoader = new THREE.TextureLoader(this.loadingMgr);  // Loader for 2D textures
        this.textureLoader.setPath(this.baseURL);

        this.audioLoader = new THREE.AudioLoader(this.loadingMgr);  // Loader for audio files
        this.audioLoader.setPath(this.baseURL);
        
        this.cubeTextureLoader = new THREE.CubeTextureLoader(this.loadingMgr);  // Loader for cube textures (cubemaps)
        this.cubeTextureLoader.setPath(this.baseURL);

        this.gltfLoader = new GLTFLoader(this.loadingMgr);  // Loader for GLTF models (3D models)
        this.dracoLoader = new DRACOLoader();
        this.dracoLoader.setDecoderPath('/examples/jsm/libs/draco/');  // Path to Draco decoder
        this.gltfLoader.setDRACOLoader(this.dracoLoader);
    }

    // Load method to load assets based on the global asset configuration
    load() {
        // Extracting the assets configuration from the global variable
        let assetsToLoad = Global.assets;

        // Iterating through different types of assets (textures, cubemaps, glbs)
        Object.keys(assetsToLoad).forEach((typeKey) => {
            let itemKeys = Object.keys(assetsToLoad[typeKey]);

            // Iterating through individual items within each asset type
            itemKeys.forEach((itemKey) => {
                // Loading textures and storing them in the class instance
                if (typeKey === ASSET_TYPES.TEXTURES) {
                    this[assetsToLoad[typeKey][itemKey]['key']] = this.textureLoader.load(assetsToLoad[typeKey][itemKey]['path']);
                    Global.assets[typeKey][itemKey]['asset'] = this[assetsToLoad[typeKey][itemKey]['key']];
                }

                // Loading cubemaps and storing them in the class instance
                if (typeKey === ASSET_TYPES.CUBEMAP) {
                    this[assetsToLoad[typeKey][itemKey]['key']] = this.cubeTextureLoader.load(assetsToLoad[typeKey][itemKey]['path'], function (texture) {
                        texture.minFilter = THREE.LinearMipmapLinearFilter; // Enable mipmapping
                        texture.generateMipmaps = false; // Generate mipmaps
                        texture.colorSpace= THREE.SRGBColorSpace;
                        // Additional setup if necessary
                    });
                    this[assetsToLoad[typeKey][itemKey]['key']].mapping = THREE.CubeReflectionMapping;
                    Global.assets[typeKey][itemKey]['asset'] = this[assetsToLoad[typeKey][itemKey]['key']];
                }

                // Loading glbs and storing them in the class instance
                if (typeKey === ASSET_TYPES.GLBS) {
                    this[assetsToLoad[typeKey][itemKey]['key']] = this.gltfLoader.load(assetsToLoad[typeKey][itemKey]['path'][0], (gltf) => {
                        Global.assets[typeKey][itemKey]['asset'] = gltf;
                    });
                }
            });
        });
    }
}
