
precision highp float;
precision highp int;

// Default THREE.js uniforms available to both fragment and vertex shader

uniform sampler2D uTexture;
uniform float uOpacity;

// Default uniforms provided by ShaderFrog.
uniform float uTime;
varying vec2 vUv;



void main() {

    vec4 color= texture2D(uTexture, vUv);

    gl_FragColor = vec4(color);

}