
precision highp float;
precision highp int;

// Default THREE.js uniforms available to both fragment and vertex shader

uniform sampler2D uTexture;

// Default uniforms provided by ShaderFrog.
uniform float uTime;
varying vec2 vUv;



void main() {

    vec4 color= texture2D(uTexture, vUv);
    if(color.a<0.85){
        color.a=0.;
    }
    
    // color.r *= max((1.- uTime),1.*sin(smoothstep(0., 1., (1.-vUv.y)*uTime)));
    // color.g *= max((1.- uTime),0.05*sin(smoothstep(0., 1., (1.-vUv.y)*uTime)));
    // color.b *= max((1.- uTime),0.27*sin(smoothstep(0., 1., (1.-vUv.y)*uTime)));
    gl_FragColor = vec4(color);

}