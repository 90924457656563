import { isMobile } from "mobile-device-detect";

var player;
/* var done = false; */
function playYoutubeVideo(_id) {
    document.getElementById("youtube_player_sec").style.display = "block";
    return onYouTubeIframeAPIReady(_id);
}

function onYouTubeIframeAPIReady(_id) {
    player = new YT.Player('youtube-player', {
        height: ((isMobile)?'35%':'60%'),
        width: ((isMobile)?'90%':'55%'),
        videoId: _id,
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        }
    });
    return player;
}

function onPlayerReady(event) {
    console.log(event.target," target")
    event.target.playVideo();
}

function changeVideo(_id){
    document.getElementById("youtube_player_sec").style.display = "block";
    player.loadVideoById(_id);
    player.playVideo();
}



function onPlayerStateChange(event) {
    /* if (event.data == YT.PlayerState.PLAYING && !done) {
        console.log("RREdirected")
        setTimeout(stopVideo, 6000);
        done = true;
    } */
}

function stopVideo() {
    console.log("stop Track",player)
    player.stopVideo();
}
export {
    playYoutubeVideo,
    changeVideo,
    stopVideo
};