let Global={
    BLOOM_SCENE:1,
    energyTotal:0,
    playCount:0,
    scoreTotal:0,
    backgroundIntensity:0.8,
    delta:1,
    roadMat:null,
    fps:60,
    isGyro:false,
    userCar:null,
    controlType:null,
    miniMapScene:null,
    BLOOM_SCENE:1,
    tiltAlpha:0,
    titleDirection:1,
    leftLane:[],
    rightLane:[],
    leftLaneMesh: [],
    rightLaneMesh:[],
    cars:[],
    speedLimiters:[],
    speedIndicator:[],
    speedActivators:[],
    carPositionIndex:{

    },
    assets:{
        "cubemap":{
            "0":{
                'key':'environmentMapTexture',
                "path":[
                    'textures/environmentMaps/1/px.png',
                    'textures/environmentMaps/1/nx.png',
                    'textures/environmentMaps/1/py.png',
                    'textures/environmentMaps/1/ny.png',
                    'textures/environmentMaps/1/pz.png',
                    'textures/environmentMaps/1/nz.png'
                ],
                "asset":null
            }
        },
        "glbs":{
            
            "can":{
                'key':'can',
                "path": [
                    './models/can.glb'
                ],
                "asset":null
            },
            "can2":{
                'key':'can2',
                "path": [
                    './models/can2.glb'
                ],
                "asset":null
            }
        },
        "textures":{
            "SMOOTH_NOISE2":{
                'key':'SMOOTH_NOISE',
                "path": [
                    'textures/img4.png'
                ],
                "asset":null
            },
            "SMOOTH_NOISE":{
                'key':'SMOOTH_NOISE',
                "path": [
                    'textures/smooth_noise3.png'
                ],
                "asset":null
            },
            "COVER_DEFAULT":{
                'key':'COVER_DEFAULT',
                "path": [
                    'textures/COVER_DEFAULT.png'
                ],
                "asset":null
            },
            "COVER_BLUE":{
                'key':'COVER_BLUE',
                "path": [
                    'textures/COVER_BLUE.png'
                ],
                "asset":null
            },
            "COVER_PINK":{
                'key':'COVER_PINK',
                "path": [
                    'textures/COVER_PINK.png'
                ],
                "asset":null
            },
            "COVER_RED":{
                'key':'COVER_RED',
                "path": [
                    'textures/COVER_RED.png'
                ],
                "asset":null
            },
            "COVER_GREEN":{
                'key':'COVER_GREEN',
                "path": [
                    'textures/COVER_GREEN.png'
                ],
                "asset":null
            },
            "X_FONDO":{
                'key':'X_FONDO',
                "path": [
                    'textures/X_FONDO.png'
                ],
                "asset":null
            },
            "Lightning_RED00":{
                'key':'Lightning_RED00',
                "path": [
                    'textures/Lightning/red/Lightning_RED00.png'
                ],
                "asset":null
            },
            "Lightning_RED01":{
                'key':'Lightning_RED01',
                "path": [
                    'textures/Lightning/red/Lightning_RED01.png'
                ],
                "asset":null
            },
            "Lightning_RED02":{
                'key':'Lightning_RED02',
                "path": [
                    'textures/Lightning/red/Lightning_RED02.png'
                ],
                "asset":null
            },
            "Lightning_RED03":{
                'key':'Lightning_RED03',
                "path": [
                    'textures/Lightning/red/Lightning_RED03.png'
                ],
                "asset":null
            },
            "Lightning_RED04":{
                'key':'Lightning_RED04',
                "path": [
                    'textures/Lightning/red/Lightning_RED04.png'
                ],
                "asset":null
            },
            "Lightning_RED05":{
                'key':'Lightning_RED05',
                "path": [
                    'textures/Lightning/red/Lightning_RED05.png'
                ],
                "asset":null
            },
            "Lightning_RED06":{
                'key':'Lightning_RED06',
                "path": [
                    'textures/Lightning/red/Lightning_RED06.png'
                ],
                "asset":null
            },
            "Lightning_RED07":{
                'key':'Lightning_RED07',
                "path": [
                    'textures/Lightning/red/Lightning_RED07.png'
                ],
                "asset":null
            },
            "Lightning_RED08":{
                'key':'Lightning_RED08',
                "path": [
                    'textures/Lightning/red/Lightning_RED08.png'
                ],
                "asset":null
            },
            "Lightning_RED09":{
                'key':'Lightning_RED09',
                "path": [
                    'textures/Lightning/red/Lightning_RED09.png'
                ],
                "asset":null
            },
            "Lightning_RED10":{
                'key':'Lightning_RED10',
                "path": [
                    'textures/Lightning/red/Lightning_RED10.png'
                ],
                "asset":null
            },
            "Lightning_RED11":{
                'key':'Lightning_RED11',
                "path": [
                    'textures/Lightning/red/Lightning_RED11.png'
                ],
                "asset":null
            },
            "Lightning_RED12":{
                'key':'Lightning_RED12',
                "path": [
                    'textures/Lightning/red/Lightning_RED12.png'
                ],
                "asset":null
            },
            "Lightning_RED13":{
                'key':'Lightning_RED13',
                "path": [
                    'textures/Lightning/red/Lightning_RED13.png'
                ],
                "asset":null
            },
            "Lightning_RED14":{
                'key':'Lightning_RED14',
                "path": [
                    'textures/Lightning/red/Lightning_RED14.png'
                ],
                "asset":null
            },
            /* "Lightning_RED15":{
                'key':'Lightning_RED15',
                "path": [
                    'textures/Lightning/red/Lightning_RED15.png'
                ],
                "asset":null
            },
            "Lightning_RED16":{
                'key':'Lightning_RED16',
                "path": [
                    'textures/Lightning/red/Lightning_RED16.png'
                ],
                "asset":null
            },
            "Lightning_RED17":{
                'key':'Lightning_RED17',
                "path": [
                    'textures/Lightning/red/Lightning_RED17.png'
                ],
                "asset":null
            },
            "Lightning_RED18":{
                'key':'Lightning_RED18',
                "path": [
                    'textures/Lightning/red/Lightning_RED18.png'
                ],
                "asset":null
            },
            "Lightning_RED19":{
                'key':'Lightning_RED19',
                "path": [
                    'textures/Lightning/red/Lightning_RED19.png'
                ],
                "asset":null
            },
            "Lightning_RED20":{
                'key':'Lightning_RED20',
                "path": [
                    'textures/Lightning/red/Lightning_RED20.png'
                ],
                "asset":null
            }, */
            "Lightning_BLUE00":{
                'key':'Lightning_BLUE00',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE00.png'
                ],
                "asset":null
            },
            "Lightning_BLUE01":{
                'key':'Lightning_BLUE01',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE01.png'
                ],
                "asset":null
            },
            "Lightning_BLUE02":{
                'key':'Lightning_BLUE02',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE02.png'
                ],
                "asset":null
            },
            "Lightning_BLUE03":{
                'key':'Lightning_BLUE03',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE03.png'
                ],
                "asset":null
            },
            "Lightning_BLUE04":{
                'key':'Lightning_BLUE04',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE04.png'
                ],
                "asset":null
            },
            "Lightning_BLUE05":{
                'key':'Lightning_BLUE05',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE05.png'
                ],
                "asset":null
            },
            "Lightning_BLUE06":{
                'key':'Lightning_BLUE06',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE06.png'
                ],
                "asset":null
            },
            "Lightning_BLUE07":{
                'key':'Lightning_BLUE07',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE07.png'
                ],
                "asset":null
            },
            "Lightning_BLUE08":{
                'key':'Lightning_BLUE08',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE08.png'
                ],
                "asset":null
            },
            "Lightning_BLUE09":{
                'key':'Lightning_BLUE09',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE09.png'
                ],
                "asset":null
            },
            "Lightning_BLUE10":{
                'key':'Lightning_BLUE10',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE10.png'
                ],
                "asset":null
            },
            "Lightning_BLUE11":{
                'key':'Lightning_BLUE11',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE11.png'
                ],
                "asset":null
            },
            "Lightning_BLUE12":{
                'key':'Lightning_BLUE12',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE12.png'
                ],
                "asset":null
            },
            "Lightning_BLUE13":{
                'key':'Lightning_BLUE13',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE13.png'
                ],
                "asset":null
            },
            "Lightning_BLUE14":{
                'key':'Lightning_BLUE14',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE14.png'
                ],
                "asset":null
            },
            /* "Lightning_BLUE15":{
                'key':'Lightning_BLUE15',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE15.png'
                ],
                "asset":null
            },
            "Lightning_BLUE16":{
                'key':'Lightning_BLUE16',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE16.png'
                ],
                "asset":null
            },
            "Lightning_BLUE17":{
                'key':'Lightning_BLUE17',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE17.png'
                ],
                "asset":null
            },
            "Lightning_BLUE18":{
                'key':'Lightning_BLUE18',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE18.png'
                ],
                "asset":null
            },
            "Lightning_BLUE19":{
                'key':'Lightning_BLUE19',
                "path": [
                    'textures/Lightning/blue/Lightning_BLUE19.png'
                ],
                "asset":null
            },
            "Lightning_BLUE20":{
                'key':'Lightning_BLUE20',
                "path": [
                    'textures/Lightning/blue/Lightning_RED20.png'
                ],
                "asset":null
            }, */
            "Lightning_GREEN00":{
                'key':'Lightning_GREEN00',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN00.png'
                ],
                "asset":null
            },
            "Lightning_GREEN01":{
                'key':'Lightning_GREEN01',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN01.png'
                ],
                "asset":null
            },
            "Lightning_GREEN02":{
                'key':'Lightning_GREEN02',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN02.png'
                ],
                "asset":null
            },
            "Lightning_GREEN03":{
                'key':'Lightning_GREEN03',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN03.png'
                ],
                "asset":null
            },
            "Lightning_GREEN04":{
                'key':'Lightning_GREEN04',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN04.png'
                ],
                "asset":null
            },
            "Lightning_GREEN05":{
                'key':'Lightning_GREEN05',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN05.png'
                ],
                "asset":null
            },
            "Lightning_GREEN06":{
                'key':'Lightning_GREEN06',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN06.png'
                ],
                "asset":null
            },
            "Lightning_GREEN07":{
                'key':'Lightning_GREEN07',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN07.png'
                ],
                "asset":null
            },
            "Lightning_GREEN08":{
                'key':'Lightning_GREEN08',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN08.png'
                ],
                "asset":null
            },
            "Lightning_GREEN09":{
                'key':'Lightning_GREEN09',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN09.png'
                ],
                "asset":null
            },
            "Lightning_GREEN10":{
                'key':'Lightning_GREEN10',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN10.png'
                ],
                "asset":null
            },
            "Lightning_GREEN11":{
                'key':'Lightning_GREEN11',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN11.png'
                ],
                "asset":null
            },
            "Lightning_GREEN12":{
                'key':'Lightning_GREEN12',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN12.png'
                ],
                "asset":null
            },
            "Lightning_GREEN13":{
                'key':'Lightning_GREEN13',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN13.png'
                ],
                "asset":null
            },
            "Lightning_GREEN14":{
                'key':'Lightning_GREEN14',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN14.png'
                ],
                "asset":null
            },
            /* "Lightning_GREEN15":{
                'key':'Lightning_GREEN15',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN15.png'
                ],
                "asset":null
            },
            "Lightning_GREEN16":{
                'key':'Lightning_GREEN16',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN16.png'
                ],
                "asset":null
            },
            "Lightning_GREEN17":{
                'key':'Lightning_GREEN17',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN17.png'
                ],
                "asset":null
            },
            "Lightning_GREEN18":{
                'key':'Lightning_GREEN18',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN18.png'
                ],
                "asset":null
            },
            "Lightning_GREEN19":{
                'key':'Lightning_GREEN19',
                "path": [
                    'textures/Lightning/green/Lightning_GREEN19.png'
                ],
                "asset":null
            },
            "Lightning_GREEN20":{
                'key':'Lightning_GREEN20',
                "path": [
                    'textures/Lightning/green/Lightning_RED20.png'
                ],
                "asset":null
            }, */
            "Lightning_PINK00":{
                'key':'Lightning_PINK00',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK00.png'
                ],
                "asset":null
            },
            "Lightning_PINK01":{
                'key':'Lightning_PINK01',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK01.png'
                ],
                "asset":null
            },
            "Lightning_PINK02":{
                'key':'Lightning_PINK02',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK02.png'
                ],
                "asset":null
            },
            "Lightning_PINK03":{
                'key':'Lightning_PINK03',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK03.png'
                ],
                "asset":null
            },
            "Lightning_PINK04":{
                'key':'Lightning_PINK04',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK04.png'
                ],
                "asset":null
            },
            "Lightning_PINK05":{
                'key':'Lightning_PINK05',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK05.png'
                ],
                "asset":null
            },
            "Lightning_PINK06":{
                'key':'Lightning_PINK06',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK06.png'
                ],
                "asset":null
            },
            "Lightning_PINK07":{
                'key':'Lightning_PINK07',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK07.png'
                ],
                "asset":null
            },
            "Lightning_PINK08":{
                'key':'Lightning_PINK08',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK08.png'
                ],
                "asset":null
            },
            "Lightning_PINK09":{
                'key':'Lightning_PINK09',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK09.png'
                ],
                "asset":null
            },
            "Lightning_PINK10":{
                'key':'Lightning_PINK10',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK10.png'
                ],
                "asset":null
            },
            "Lightning_PINK11":{
                'key':'Lightning_PINK11',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK11.png'
                ],
                "asset":null
            },
            "Lightning_PINK12":{
                'key':'Lightning_PINK12',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK12.png'
                ],
                "asset":null
            },
            "Lightning_PINK13":{
                'key':'Lightning_PINK13',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK13.png'
                ],
                "asset":null
            },
            "Lightning_PINK14":{
                'key':'Lightning_PINK14',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK14.png'
                ],
                "asset":null
            },
            /* "Lightning_PINK15":{
                'key':'Lightning_PINK15',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK15.png'
                ],
                "asset":null
            },
            "Lightning_PINK16":{
                'key':'Lightning_PINK16',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK16.png'
                ],
                "asset":null
            },
            "Lightning_PINK17":{
                'key':'Lightning_PINK17',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK17.png'
                ],
                "asset":null
            },
            "Lightning_PINK18":{
                'key':'Lightning_PINK18',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK18.png'
                ],
                "asset":null
            },
            "Lightning_PINK19":{
                'key':'Lightning_PINK19',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK19.png'
                ],
                "asset":null
            },
            "Lightning_PINK20":{
                'key':'Lightning_PINK20',
                "path": [
                    'textures/Lightning/pink/Lightning_PINK20.png'
                ],
                "asset":null
            } */
            
        }
    }
}
export{
    Global
}