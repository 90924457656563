// Importing necessary modules from the 'three' library and other dependencies
import * as THREE from 'three';
import EventDispatcher from '../common/EventDispatcher';
import {
    gsap,
    Expo,
    Cubic
} from 'gsap';
import {
    Global
} from '../common/global';

// Constants for camera position and orientation
const INITIAL_LANDSCAPE_CAMERA_POSITION = new THREE.Vector3(0,0,4.2);
const INITIAL_PORTRAIT_CAMERA_POSITION = new THREE.Vector3(0,0.0,4);// new THREE.Vector3(0,0.15,6);
const INITIAL_LOOK_AT_POSITION = new THREE.Vector3(0, 0, 0);

// Constants for camera follow lerping factors
const CAMERA_FOLLOW_LERP_X = 0.0375;
const CAMERA_FOLLOW_LERP_Y = 0.04;
const CAMERA_FOLLOW_LERP_Z = 0.25;

// Constant for additional padding on Y-axis when looking at the player's car
const CAMERA_LOOKAT_Y_PADD = 1.5;

// Constants for lerping vectors for smooth camera follow
const YLERP_VECTOR = new THREE.Vector3(0, 0.1, 0);
const ZLERP_VECTOR = new THREE.Vector3(0, 0.07, 0.001);

// Constants for default camera shake value
const DEFAULT_SHAKE_VALUE = {
    'value': 0.0
};

// Camera class definition, extending THREE.PerspectiveCamera
export default class Camera extends THREE.PerspectiveCamera {
    // Vector3 instances for lerping camera position
    zLerp = new THREE.Vector3();
    yLerp = new THREE.Vector3();
    xLerp = new THREE.Vector3();

    // Flag indicating whether the game has started
    gameStarted = false;

    // Constructor with fov, ratio, near, and far parameters
    constructor(fov, ratio, near, far) {
        super(fov, ratio, near, far);
    }

    // Initialization method for setting up event listeners and initial camera state
    init() {
        // Getting the event dispatcher instance
        this.events = EventDispatcher.getObj();

        // Adding event listeners for different event types
        this.events.addEventListener("CAMERA_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("PLAYER_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("CAMERA_EVENTS", this.onEvents.bind(this));

        // Initializing rotation value vector
        this.rotateVal = new THREE.Vector3(0, 0, 0);

        // Setting initial camera position
        this.position.copy(INITIAL_LANDSCAPE_CAMERA_POSITION);

        // Initializing lerping vectors for smooth camera follow
        this.yLerp.copy(YLERP_VECTOR);
        this.zLerp.copy(ZLERP_VECTOR);
        this.rotationVal = new THREE.Vector3(0, 0, 0);
        this.xLerp = this.position.clone();
        this.isPortrait=false;
        this.initCameraFixed = false;
        this.zoomLevel={
            'value': 0
        }

        // Initializing shake value
        this.shakeVal = DEFAULT_SHAKE_VALUE;

        // Setting initial look-at position
        this.lookAt(INITIAL_LOOK_AT_POSITION);
    }
    updateInitPos(data){
        if(this.isPortrait){
            this.zoomOutTwn && this.zoomOutTwn.kill();
            let cameraPos= {
                'value': this.position.clone()
            }
            console.log(data['zoomLevel'],"data['zoomLevel']")
            // this.position.copy(INITIAL_PORTRAIT_CAMERA_POSITION).add(new THREE.Vector3(0,0.15 * data['zoomLevel'],2 * data['zoomLevel']));
            this.zoomOutTwn= gsap.to(this.zoomLevel, {
                duration: 0.25,
                delay: 0,
                value: (data['zoomLevel']),
                repeat: 0,
                ease: Cubic.easeOut,
                onUpdate: function () {
                    // console.log(INITIAL_PORTRAIT_CAMERA_POSITION.add(new THREE.Vector3(0,0.15 * this.zoomLevel['value'],2 * this.zoomLevel['value'])),' zoomLevel')
                    this.position.set(...INITIAL_PORTRAIT_CAMERA_POSITION).add(new THREE.Vector3(0,0.15 * this.zoomLevel['value'],2 * this.zoomLevel['value']))
                }.bind(this)
            });
        }
    }
    updateCameraPosition(data){
        if(data['sizes']['width'] < data['sizes']['height']){
            this.isPortrait= true;
            // this.position.copy(INITIAL_PORTRAIT_CAMERA_POSITION);
            this.position.set(...INITIAL_PORTRAIT_CAMERA_POSITION).add(new THREE.Vector3(0,0.15 * this.zoomLevel['value'],2 * this.zoomLevel['value']))

        }else{
            this.isPortrait= false;
            this.position.copy(INITIAL_LANDSCAPE_CAMERA_POSITION);
        }
        // console.log(data['sizes'],'aspect')
        // this.position.copy(INITIAL_LANDSCAPE_CAMERA_POSITION);
    }

    // Event handler for various events
    onEvents(data) {
        switch (data['message']['event_type']) {
            case "zoom_on_tilt":
                this.updateInitPos(data['message']['data']);
                break;
            case "resize":
                this.onResize(data['message']['data']);
                break;
            case "update":
                this.update();
                break;
            case "move_player":
                this.smoothFollowPlayer(data['message']['data']);
                break;
            case "shake_camera":
                this.prepareToShake(data['message']['data']);
                break;
            case "start_game":
                this.gameStarted = true;
                break;
        }
    }

    // Event handler for window resize
    onResize(data) {
        this.aspect = data.aspect;
        this.updateProjectionMatrix();
        this.updateCameraPosition(data);
    }

    // Update method (currently empty, can be used for additional logic)
    update() {
        // console.log(this.position)
        // ...
    }

    // Method for smoothly following the player's car
    smoothFollowPlayer(data) {
        // Calculating camera offset based on the player's car position and orientation
        var cameraOffset = new THREE.Vector3(0, 2.5, 5);
        var carDirection = new THREE.Vector3(0, 0, -1.6);
        var cameraPosition = new THREE.Vector3();
        cameraPosition.copy(data.entity.position);
        cameraPosition.add(carDirection.clone().applyQuaternion(data.entity.quaternion).multiplyScalar(cameraOffset.z));
        cameraPosition.add(data.entity.up.clone().multiplyScalar(cameraOffset.y));
        cameraPosition.add(new THREE.Vector3(-1, 0, 0).clone().multiplyScalar(cameraOffset.x));
        // cameraPosition.sub(new THREE.Vector3(Math.random() * this.shakeVal['value'] - this.shakeVal['value'] / 2));

        // Lerping camera position and applying a random shake effect
        let distanceLerpX = this.position.clone().lerp(cameraPosition, (!this.initCameraFixed ? 1 : Math.min(1, CAMERA_FOLLOW_LERP_X * Global.delta))).x;
        let distanceLerpY = this.position.clone().lerp(cameraPosition, !this.initCameraFixed ? 1 : Math.min(1, CAMERA_FOLLOW_LERP_Y * Global.delta + (cameraPosition.x < this.position.x ? (this.position.x - cameraPosition.x) / 30 * Global.delta : 0))).y;
        let distanceLerpZ = this.position.clone().lerp(cameraPosition, !this.initCameraFixed ? 1 : Math.min(1, CAMERA_FOLLOW_LERP_Z * Global.delta)).z;
        this.position.set(distanceLerpX, distanceLerpY, distanceLerpZ);
        let pos = data.entity.position.clone();

        let shakeFact = new THREE.Vector3(this.shakeVal['value'] == 0 ? 0 : (Math.random() * this.shakeVal['value'] - this.shakeVal['value'] / 2));
        this.position.add(shakeFact);

        // Setting camera orientation to look at the player's car
        this.lookAt(new THREE.Vector3(pos.x - shakeFact.x * 0, pos.y + CAMERA_LOOKAT_Y_PADD - shakeFact.y * 0, pos.z - shakeFact.z * 0));

        this.initCameraFixed = true;
    }

    // Method to prepare for camera shake animation
    prepareToShake(data) {
        this.shakeVal = {
            'value': data['intensity']
        };

        // Using gsap to animate the shake effect
        gsap.to(this.shakeVal, {
            duration: data['shake_time'],
            delay: 0,
            value: 0,
            repeat: 0,
            ease: "power1.inOut",
            onUpdate: function () {}.bind(this)
        });
    }
}
