// Importing necessary modules and dependencies
import * as THREE from 'three';
import EventDispatcher from '../common/EventDispatcher';
import {
    Global
} from '../common/global';
import {
    gsap,
    Back
} from 'gsap';
import XVertexShader from '../shader/x/vertex.glsl';
import XFragmentShader from '../shader/x/fragment.glsl';


// Definition of the GameScene class
export default class XFONDO {
    // Constructor takes a scene and camera as parameters
    constructor(scene, camera) {
        this.scene = scene;
        this.camera = camera;
    }

    // Initialization method for setting up event listeners and initial state
    init() {

        this.xTwn=null;
        this.xLogoAdded= false;
        // Getting the event dispatcher instance
        this.events = EventDispatcher.getObj();
        this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));


    }
    onEvents(data){
        switch(data['message']['event_type']){
            case "create_scene":
                this.addX();
                break;
            case "add_x_logo":
                this.showX();
                break;
            case "remove_x_logo":
                this.hideX();
                break;
            case "update":
                this.update(data['message']['data']);
                break;
        }
    }
    update(data){
        if(this.xMat){
            this.xMat.uniforms.uTime.value+=0.1;
        }
    }
    showX(){
        this.xTwn && this.xTwn.kill();
        this.xTwn= gsap.to(this.xMesh.scale, {
            duration: 0.5,
            delay: 0.2,
            x: 1,
            y: 1,
            z: 1,
            ease: Back.easeOut
        });
    }
    hideX(){
        this.xTwn && this.xTwn.kill();
        this.xTwn= gsap.to(this.xMesh.scale, {
            duration: 0.35,
            delay: 0.2,
            x: 0,
            y: 0,
            z: 0,
            ease: Back.easeIn
        });
    }
    addX(){
        if(this.xLogoAdded) return false;

        this.xLogoAdded= true;
        this.xColorMap= Global.assets['textures']['X_FONDO']['asset']

        
        this.xGeo= new THREE.PlaneGeometry(6, 6);
        this.xMat= new THREE.ShaderMaterial({
            vertexShader: XVertexShader,
            fragmentShader: XFragmentShader,
            transparent:true,
            uniforms:{
                'uTexture': { value: this.xColorMap},
                'uTime': { value: 0.}
            }
        });
        this.xMesh= new THREE.Mesh(this.xGeo, this.xMat);
        this.scene.add(this.xMesh);

        this.xMesh.position.set(0,-.3,-2);
        this.xMesh.scale.set(0)

        

        

    }
}