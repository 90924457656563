import * as THREE from 'three';
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { GlitchPass } from 'three/addons/postprocessing/GlitchPass.js';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';

import EventDispatcher from '../common/EventDispatcher';



export default class PostProcessing {
    // Constructor takes a scene and camera as parameters
    constructor(scene, camera, renderer) {
        this.scene = scene;
        this.camera = camera;
        this.renderer= renderer;
    }
    init(){
        console.log(THREE.RGBShiftShader,'THREE')
        this.events = EventDispatcher.getObj();
        this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));

        this.composer = new EffectComposer( this.renderer );
        this.composer.addPass( new RenderPass( this.scene, this.camera ) );

        this.glitchPass = new GlitchPass();

        // this.glitchPass.goWild= true;

       
        this.composer.addPass( this.glitchPass );

        this.stopGlitch();
                

		const outputPass = new OutputPass();
		this.composer.addPass( outputPass );
    }
    onEvents(data){
        switch(data['message']['event_type']){
            case "start_glitch":
                this.startGlitch(data['message']['data']);
                break;
          /*   case "apply_glitch":
                this.applyGlitch(data['message']['data']);
                break; */
            case "stop_glitch":
                this.stopGlitch(data['message']['data']);
                break;
            case "update":
                this.update(data['message']['data']);
                break;
        }
    }
    startGlitch(){
        this.glitchPass.goWild = true;
        this.glitchPass.enabled= true;
        this.glitchPass.distortion = 0.01; // Adjust distortion intensity
        this.glitchPass.perturbation = 0.01; // Adjust perturbation intensity
    
    }
    stopGlitch(){
        this.glitchPass.enabled= false;
        this.glitchPass.goWild = false;
    }
   
    update(){
        this.composer.render();
    
    }
}