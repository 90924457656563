
precision highp float;
precision highp int;

// Default THREE.js uniforms available to both fragment and vertex shader

uniform sampler2D uTexture;

// Default uniforms provided by ShaderFrog.
uniform float uTime;
varying vec2 vUv;



void main() {

   
    vUv = uv;

    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

}