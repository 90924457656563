// Importing required modules from the 'three' library and custom components
import * as THREE from 'three'
import Camera from './components/Camera'

import EventDispatcher from './common/EventDispatcher'
import GUI from './components/GUI'
import AssetLoader from './components/AssetLoader';
import {
    Global
} from './common/global';
import {
    playYoutubeVideo,
    changeVideo,
    stopVideo
} from './components/youtube-player';
// Importing mobile device detection functions
import {
    isMobile,
    isMobileOnly
} from 'mobile-device-detect';
import Can from './components/Can'
import XFONDO from './components/XFondo'

import './style.scss';
import CanInfo from './components/CanInfo'
import Lightning from './components/Lightning';
import PostProcessing from './components/postprocessing';
import { createUser } from './components/api';
// import './components/date_picker';


let youTubePlayer = null;
// Variables to track time, frame rate, and delta time
let lastTime = 0;
let fps = 0;
let frameCount = 0;
let lastDeltaVec = new THREE.Vector3();
let lastScrollTop = 0;
let scrollFactor = 0;

//submitBtn
// Game class definition, extending THREE.Scene
export default class Game extends THREE.Scene {
    // Properties for environment map and screen sizes
    environmentMap = null;
    sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    // Constructor sets up event listener for window resize
    constructor() {
        super();
        window.addEventListener('resize', this.onResize.bind(this))
    }

    // Initialization method for setting up the game
    init() {
        
        this.userRegistered= false;
        window.scrollTo({
            top: 0
          });     
       
         /*  window.scrollTo({
            left:0,
            top:0
        }); */
        this.gameTime=0;
        // Setting up performance statistics display
        this.fullScreenTO = null;
        /* this.stats = new Stats();
        this.stats.showPanel(0);
        document.body.appendChild(this.stats.dom); */

        // Initializing event dispatcher for handling events
        this.events = EventDispatcher.getObj();
        this.events.addEventListener("LOADER_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("GAME_EVENTS", this.onGameEvents.bind(this));
        this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));

        // Getting the canvas element and initializing the clock
        this.canvas = document.querySelector('canvas.webgl');
        this.clock = new THREE.Clock();

        // Initializing GUI, camera, map, game scene, player car, lights, and game control
        this.gui = new GUI();
        this.gui.init();

        this.camera = new Camera(50, this.sizes.width / this.sizes.height, 0.025, 30);
        this.camera.init();
        this.add(this.camera);
        /* this.controls = new Control(this.camera, this.canvas)
        this.controls.init(); */

       
        this.can= new Can(this, this.camera);
        this.can.init(this.gui);

        this.canInfo= new CanInfo(this, this.camera);
        this.canInfo.init();

        this.xFondo= new XFONDO(this);
        this.xFondo.init();

        this.lightning= new Lightning(this, this.camera);
        this.lightning.init();
        

        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true,
            precision: 'highp',
            powerPreference: 'high-performance'
        });

        // Setting up renderer properties
        this.renderer.physicallyCorrectLights = true;
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.toneMappingExposure = 1;
        // this.renderer.premultipliedAlpha=false;
        this.renderer.setClearColor(0x000000, 0);
        this.renderer.toneMapping= THREE.ACESFilmicToneMapping;
        this.renderer.gammaFactor = 2.2;
        this.renderer.gammaOutput = true;
        this.renderer.outputColorSpace = THREE.SRGBColorSpace;
        // this.renderer.useLegacyLights = false;
        this.renderer.setSize(this.sizes.width * 1, this.sizes.height * 1)
        this.renderer.setPixelRatio(Math.min(Math.max(2, window.devicePixelRatio), isMobile ? 2 : 2.5));

        // Initializing asset loader and loading assets
        this.assetLoader = new AssetLoader(this);
        this.assetLoader.init();
        this.assetLoader.load();

        // Initiating the game loop
        this.onResize();
        this.tick();

        this.activateCampaignSlider();

        this.postProcessing= new PostProcessing(this, this.camera, this.renderer);
        this.postProcessing.init();

        
        document.querySelector("#submitBtn").addEventListener("click", this.onRegister.bind(this));

        $("#youtube_player_sec").on("click", this.hideYoutubeVideo);

        document.querySelector("#showIG").addEventListener("click", () => {
            window.open("https://www.instagram.com/p/C5Bl5fqMnxA/?igsh=bXY2YjUzdGxseDBw", "_blank");
        })
        

        document.querySelector("#showYT").addEventListener("click", this.showLargeVideo.bind(this, "zP8q1nk8K5k"))
    
    }
    async onRegister(){
        if(this.userRegistered) return false;

        let isValid = this.validateRegister();
        if (!isValid) return false;
        this.userRegistered= true;

        createUser(this.fname, this.lname, this.email, this.flavour, this.dob, this.privacyAccepted, this.newsletterAccepted)

        // $(".home_registration").animate({height:0},1000, $.easing.easeInOutCubic);
        // document.querySelector(".register_complete_info").classList.add('active')
        $(".register_complete_info").animate({height:.08*window.innerHeight},500, $.easing.easeInOutCubic);
        window.scrollTo({
            top: $(".register_complete_info").offset().top-window.innerHeight/2
          },);  
        setTimeout(() => {
            $(".register_complete_info").animate({height:0},1000, $.easing.easeInOutCubic);
            this.resetRegister();
        }, 2000)

    }
    resetRegister(){
        this.userRegistered= false;

        document.querySelector(".home_registration #fname").value="";
        document.querySelector(".home_registration #lname").value="";
        document.querySelector(".home_registration #email").value="";
        document.querySelector(".home_registration #flavour").value="";
        document.querySelector(".home_registration #datepicker").value="";
        document.querySelector("#privacy_check").checked= false;
        document.querySelector("#newsletter_check").checked= false;
    }
    showError(eleId, msg){
        document.querySelector(`#${eleId}`).classList.add('error');
        this.errorTO && clearTimeout(this.errorTO);

        document.querySelector('.register_error').innerHTML= msg;
        document.querySelector('.register_error').classList.add('active');
        this.errorTO= setTimeout(() => {
            document.querySelector('.register_error').classList.remove('active');
        }, 2000)
    }
    hideError(eleId){
        document.querySelector(`#${eleId}`).classList.remove('error');
    }
    validateRegister() {

        let isValid = true;
    
        this.fname = document.querySelector(".home_registration #fname").value.trim();
        this.lname = document.querySelector(".home_registration #lname").value.trim();
        this.email = document.querySelector(".home_registration #email").value.trim();
        this.flavour = document.querySelector(".home_registration #flavour").value.trim();
        this.dob = document.querySelector(".home_registration #datepicker").value.trim();
        this.privacyAccepted= document.querySelector("#privacy_check").checked;
        this.newsletterAccepted= document.querySelector("#newsletter_check").checked;

        if (this.fname.length == 0) {
            isValid = false;
            this.showError("fname", "Invalid First Name");
            return false;
        }else{
            this.hideError("fname");
        }
        if (this.lname.length == 0) {
            isValid = false;
            this.showError("lname", "Invalid Last Name");
            return false;
        }else{
            this.hideError("lname");
        }
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))) {
            this.showError("email", "Invalid Email");
            isValid= false;
            return false;
        }
        else if((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))){
            this.hideError("email");
        }
        if (this.flavour.length == 0) {
            isValid = false;
            this.showError("flavour", "No Flavour Selected");
            return false;
        }else{
            this.hideError("flavour");
        }
        if (this.dob.length == 0) {
            isValid = false;
            this.showError("datepicker", "Invalid DOB");
            return false;
        }else{
            this.hideError("datepicker");
        }
        if(!this.privacyAccepted){
            isValid = false;
            this.showError("privacy_check_content", "Please accept privacy policies");
            return false;
        }else{
            this.hideError("privacy_check_content");
        }
        return isValid;
    
    }
    activateCampaignSlider(){
       const slider = document.querySelector('.slider');
  const slideItems = document.querySelectorAll('.slide-item');
  let slideWidth = slideItems[0].offsetWidth + 20; // Adjust 20 for margin

  slideItems.forEach(function(item, index) {
    item.addEventListener('click', function() {
        slideWidth = slideItems[0].offsetWidth + 20;
      const activeIndex = Array.from(slideItems).findIndex(item => item.classList.contains('active'));
      const centerIndex = Math.floor(slideItems.length / 2);
      let offset;

     
      offset= index
      console.log("Offset:", offset);

      const translateValue = offset * slideWidth;

      const computedStyle = window.getComputedStyle(slider);
      const transformValue = computedStyle.getPropertyValue('transform');
      const matrix = new DOMMatrix(transformValue);
      const translateX = matrix.m41;


      let sliderFact= (((translateValue)-(window.innerWidth/3))/window.innerWidth*100);
 
      if(offset==slideItems.length-1){
        if(isMobile){
            sliderFact= Math.min(61, sliderFact);
        }else{
            sliderFact= Math.min(6, sliderFact);
        }
        
      }else{
        sliderFact= Math.min(100, sliderFact)
      }
      if(offset == 0){
        if(isMobile){
            sliderFact= -8;
        }else{
            sliderFact= -5;
        }
        
      }
      document.querySelectorAll(`.slider_nav .nav`).forEach((nav) => {
        nav.classList.remove('active');
      });
      document.querySelector(`.slider_nav .nav:nth-child(${offset+1})`).classList.add('active')
    //   sliderFact= -10;
      slider.style.transform = `translateX(${-1*sliderFact}%)`;

      slideItems.forEach(function(item) {
        item.classList.remove('active');
      });

      item.classList.add('active');
    });
  });

  // Ensure that the initial active slide is centered
  const initialActiveIndex = 0;//Math.floor(slideItems.length / 2);
  slideItems[initialActiveIndex].click();
          
    }

    // Event handler for loader events
    onEvents(data) {
        switch (data['message']['event_type']) {
            case "load_complete":
                this.startGame();
                break;
            case "start_game":
                this.startGameTimer();
                break;
        }
    }
   

    // Event handler for game events
    onGameEvents(data) {
        switch (data['message']['event_type']) {
            case "replay_game":
                this.replayGame();
                break;
        }
    }
    onScroll(){
        console.log('onScroll')
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let scrollDirection = scrollTop > lastScrollTop ? 'down' : 'up';
        if(lastScrollTop==0){
            lastScrollTop= scrollTop;
        }
        // Calculate the scroll factor
        scrollFactor = Math.abs(scrollTop - lastScrollTop);
    
        // Update last scroll position
        lastScrollTop = scrollTop;
    
        // Use scrollFactor as needed
        // console.log(scrollDirection,'Scroll Factor:', scrollFactor);

        this.events.dispatchEvent({
            type: "GAMESCENE_EVENTS",
            message: {
                "event_type": "page_scroll",
                data: {
                    "scrollSpeed": scrollFactor*.5,
                    "scrollDirection": (scrollDirection == 'up')?1:-1,
                    "scrollTop": scrollTop*5/window.innerHeight
                }
            }
        });

    }
    activateAllScreens(){
        document.querySelector("header").classList.add("active");
        document.querySelector(".banner_sec").classList.add("active");
        document.querySelector(".feature_sec").classList.add("active");
        document.querySelector(".footer_sec").classList.add("active");
    }
    // Method to start the game
    startGame() {
        // $.datepicker.regional['es'];

        /* $('#datepicker').datepicker({
            format: 'dd/mm/yyyy',
            option:$.datepicker.regional['es']
        }); */
        $('#datepicker').datepicker({
            format: 'dd/mm/yyyy',
            locale: 'es-es'
        });
    /*     $( "#datepicker" ).datepicker( "option",
        $.datepicker.regional['es'] ); */
        // $( "#datepicker" ).datepicker( "option",$.datepicker.regional['es'] );
        document.querySelector("#datepicker").addEventListener('keydown', (v) => {
            v.preventDefault();
            v.stopImmediatePropagation();
        })
        this.activateAllScreens();
        this.environmentMap = Global.assets['cubemap']['0']['asset'];
        this.environmentMap.encoding = THREE.sRGBEncoding;

        /* window.addEventListener('scroll', this.onScroll.bind(this));
        setTimeout(() => {
            this.onScroll();
        }, 100) */
      
       

        // Dispatching events to create the scene and add the player car
        this.events.dispatchEvent({
            type: "GAMESCENE_EVENTS",
            message: {
                "event_type": "create_scene",
                data: {}
            }
        });
        

    }

    // Method to handle game replay
    replayGame() {
        this.initDirectionsSaved = false;
    }

    // Event handler for window resize
    onResize() {
        console.log('onResize')
        // Update sizes
        this.sizes.width = window.innerWidth
        this.sizes.height = window.innerHeight

        // Dispatching events to update camera and renderer sizes
        this.events.dispatchEvent({
            type: "CAMERA_EVENTS",
            message: {
                "event_type": "resize",
                data: {
                    "aspect": this.sizes.width / this.sizes.height,
                    "sizes": this.sizes
                }
            }
        });
        console.log(this.sizes.width, this.sizes.height,'this.sizes.width')
       /*  if (window.innerWidth > window.innerHeight) {
            document.querySelector("#rotate").classList.remove("active");
        } else {
            document.querySelector("#rotate").classList.add("active");
        } */
        // Updating renderer size and pixel ratio
        this.renderer.setSize(this.sizes.width, this.sizes.height)
        this.renderer.setPixelRatio(Math.min(Math.max(2, window.devicePixelRatio), isMobile ? 2 : 2.5))
    }

    // Update method to render the scene and dispatch update events
    update(now, delta) {
        this.renderer.render(this, this.camera);
      
        this.events.dispatchEvent({
            type: "GAMESCENE_EVENTS",
            message: {
                "event_type": "update",
                data: {
                    "delta": delta,
                    "now": now
                }
            }
        });
    }

    // Game loop function
    tick(now) {
        // this.stats.begin();

        // Calculating delta time and frame rate
        const delta = (now - lastTime) / 1000; // Convert milliseconds to seconds
        lastTime = now;

        frameCount++;
        if (delta) {
            fps = frameCount / delta;
            frameCount = 0;
        } else {}

        // Limiting the number of ticks and updating global variables
        let ticks = Math.ceil(delta / (1 / 120));
        ticks = Math.min(ticks, 20);
        Global.fps = new THREE.Vector3(0, Global.fps, 0).lerp(new THREE.Vector3(0, fps, 0), .1).y;
        lastDeltaVec.lerp(new THREE.Vector3(0, Math.min((delta ? Math.min(delta * 100, 1) : 1) * (120 / (fps || 1)), 10), 0), .1);
        Global.delta = lastDeltaVec.y;

        // Calling the update method and ending the stats tracking
        this.update(now, lastDeltaVec.y);
        // this.stats.end();

        // Requesting the next frame
        window.requestAnimationFrame(this.tick.bind(this))
    }

    showLargeVideo(_id) {
        if (youTubePlayer == null) {
            youTubePlayer = playYoutubeVideo(_id);
        } else {
            changeVideo(_id);
        }
    
    }
    hideYoutubeVideo() {
        if (youTubePlayer != null) {
            stopVideo();
        }
        $("#youtube_player_sec").hide();
    
    }
 
}

// Creating an instance of the Game class and initializing the game
window.onload = function () {
    
    setTimeout(() => {
        const game = new Game();
        game.init();
    }, 10)
}

