import axios from "axios";
import {
    Global
} from "../common/global";
import { isMobile } from "mobile-device-detect";

const BASE_URL = './';
import { v4 as uuidv4 } from 'uuid';

async function createUser(fname, lname, email, flavour, dob, privacy, newsletter) {
    let data = {
        "uid": uuidv4(), 
        "fname": fname,
        "lname": lname,
        "email": email,
        "flavour": flavour,
        "dob": dob,
        "privacy_checked": privacy?'1':'0',
        "newsletter_checked": newsletter?'1':'0',
        "device": isMobile?"mobile":"web"
    }
    const res = await axios.post(`${BASE_URL}create.php`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}





export {
    createUser
}