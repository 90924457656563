// Importing necessary modules and dependencies
import * as THREE from 'three';
import EventDispatcher from '../common/EventDispatcher';
import {
    Global
} from '../common/global';
import {
    gsap,
    Back
} from 'gsap';
import LightningVertexShader from '../shader/lightning/vertex.glsl';
import LightningFragmentShader from '../shader/lightning/fragment.glsl';
import { isMobile } from 'mobile-device-detect';


// Definition of the GameScene class
export default class Lightning {
    // Constructor takes a scene and camera as parameters
    constructor(scene, camera) {
        this.scene = scene;
        this.camera = camera;
    }

    // Initialization method for setting up event listeners and initial state
    init() {

        this.xLogoAdded= false;
        // Getting the event dispatcher instance
        this.events = EventDispatcher.getObj();
        this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("CAMERA_EVENTS", this.onEvents.bind(this));

       

    }
    onEvents(data){
        switch(data['message']['event_type']){
            case "create_scene":
                this.addLightning();
                break;
            case "add_lightning":
                this.showLightning(1, data['message']['data']['canKey']);
                break;
            case "update":
                this.update(data['message']['data']);
                break;
            case "resize":
                this.onResize(data['message']['data']);
                break;
        }
    }
    update(data){
        if(this.xMat){
            this.xMat.uniforms.uTime.value+=0.1;
        }
    }
    shakeCamera(intensity, duration) {
        console.log(this.camera,'this.camera.position')
        const startPosition = this.camera.position.clone();
        const startTime = Date.now();
        
        function update() {
            const elapsed = Date.now() - startTime;
            const progress = Math.min(1, elapsed / duration);
            const noiseX = (Math.random() - 0.5) * intensity * (1 - progress);
            const noiseY = (Math.random() - 0.5) * intensity * (1 - progress);
            this.camera.position.x = startPosition.x + noiseX;
            this.camera.position.y = startPosition.y + noiseY;
            
            if (progress < 1) {
                requestAnimationFrame(update.bind(this));
            }
        }
        
        update.call(this);
    }
    showLightning(opacityVal, colorKey){
        // if(colorKey!='blue') return false;
        console.log(colorKey,'colorKey')
        let i=0;
        this.lightningTI && clearInterval(this.lightningTI);
        this.shakeCamera(0.1, 500);
       /*  this.events.dispatchEvent({
            type: "GAMESCENE_EVENTS",
            message: {
                "event_type": "start_glitch",
                data: {}
            }
        });
        setTimeout(() => {
            // this.canShowGlitch=false;
            this.events.dispatchEvent({
                type: "GAMESCENE_EVENTS",
                message: {
                    "event_type": "stop_glitch",
                    data: {}
                }
            });
        }, 100); */
        this.lightningTI= setInterval(() => {
            if(i<=14){

                this.lightMat.uniforms.uTexture.value= this[`light_${colorKey}_${i}Texture`];
                this.lightMat.uniforms.uOpacity.value = opacityVal;
                this.lightMat.needsUpdate = true; 
            }
            i++;
            if(i>=15){
                // i=0;
                clearInterval(this.lightningTI)
            }
        }, 50)
    }
    addLightning(){
       for(let i=0;i<14;i++){
        this[`light_red_${i}Texture`]= Global.assets['textures'][`Lightning_RED${i<10?'0':''}${i}`]['asset'];
        this[`light_blue_${i}Texture`]= Global.assets['textures'][`Lightning_BLUE${i<10?'0':''}${i}`]['asset'];
        this[`light_green_${i}Texture`]= Global.assets['textures'][`Lightning_GREEN${i<10?'0':''}${i}`]['asset'];
        this[`light_pink_${i}Texture`]= Global.assets['textures'][`Lightning_PINK${i<10?'0':''}${i}`]['asset'];
       }

        // this.lightTexture= Global.assets['textures']['COVER_RED']['asset']
        //
        
        if(isMobile){
            this.lightGeo= new THREE.PlaneGeometry(5.5*1.78*1.2, 5*1.2);
        }else{
            this.lightGeo= new THREE.PlaneGeometry(5.5*1.78*1.3, 5*1.3);
        }
        this.lightMat= new THREE.ShaderMaterial({
            vertexShader: LightningVertexShader,
            fragmentShader: LightningFragmentShader,
            transparent: true,
            uniforms:{
                'uTexture': { value: this.light1Texture},
                'uTime': { value: 0.},
                'uOpacity': { value: 1. }
            }
        });
        this.lightMesh= new THREE.Mesh(this.lightGeo, this.lightMat);
        this.scene.add(this.lightMesh);

        this.lightMesh.position.set(0,-.2,-1.8);
        // this.lightMesh.scale.set(0.1)

       /*  gsap.to(this.lightMesh.scale, {
            duration: 0.5,
            delay: 0.2,
            x: 1,
            y: 1,
            z: 1,
            ease: Back.easeOut
        }); */
        // this.showLightning(0, 'red');
       
    }
    onResize(data){
        console.log(data['aspect'],' data');
        if(this.lightMesh){
            // this.lightMesh.scale.set(1*data['aspect']*.57, 1*data['aspect']*.57, 1)
        }
    }
}