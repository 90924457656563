// Importing necessary modules and dependencies
import * as THREE from 'three';
import EventDispatcher from '../common/EventDispatcher';
import {
    Global
} from '../common/global';
import {
    Sky
} from 'three/addons/objects/Sky.js';
import {
    isMobile
} from 'mobile-device-detect';


import {
    gsap,
    Cubic,
    Power1,
    Elastic,
    Back,
    Linear
} from 'gsap';
import CustomShaderMaterial from 'three-custom-shader-material/vanilla'

import canVertexShader from '../shader/can/vertex.glsl';
import canFragmentShader from '../shader/can/fragment.glsl';


let wheelInitiated= false;
let scrollDelta=0
let scrollTotal=0;
let text1Twn=null;
let text2Twn=null;
let initYRotate= null;
let initYRotate2= null;
let initZRotate= null;
let initZRotate2= null;
let lastRotate=0;
let startY = 0;
let _scrollTop=0;

let smoothScrollTime= 0.5;// 0.1



let canRevealNew=false;
let canRevealInProgress= false;
let canRevealed=false;
// let direct

// Definition of the GameScene class
export default class Can {
    // Constructor takes a scene and camera as parameters
    constructor(scene, camera) {
        this.scene = scene;
        this.camera = camera;


        this.scrollDisabled=false;
        this.canShowGlitch=false;
        this.bottleRevealed=false;
        this.canTwn= null;
        this.idleTwn=null;
        this.coverIndex=0;
        this.nextRot=0;
        this.nextDef=0;
        this.oldCoverMat=null;
        this.canMaterial=null;
        this.revelAmount=10;
        this.xLogoShown=false;
        this.registerRevealTwn=false;
        this.canFrames= [
            {
                "key":"redCover",
                "range":{'min': 3.14, 'max': 3.14*3}
            },
            {
                "key":"blueCover",
                "range":{'min': 3.14*3, 'max': 3.14*5}
            },
            {
                "key":"greenCover",
                "range":{'min': 3.14*5, 'max': 3.14*7}
            },
            {
                "key":"pinkCover",
                "range":{'min': 3.14*7, 'max': 3.14*9}
            }
        ]

        this.onWheelScroll= this.onWheelScroll.bind(this);
        this.onTouchStart= this.onTouchStart.bind(this);
        this.onTouchMove= this.onTouchMove.bind(this);
    }

    // Initialization method for setting up event listeners and initial state
    init(gui) {

        this.gui= gui;

        this.canLight={
            'value': 5
        }
        this.gui.add(this.canLight,'value', 0,5).step(0.01).name('Can Light').onChange(()=>{
            this.canModel.traverse((item) => {
                if(item.material && item.material.name.indexOf('mat_grphic') !== -1){
                    item.material.envMapIntensity= this.canLight['value']
                    
                    // item.material.uniforms.uTexture.value= this[this.canFrames[this.coverIndex]['key']];
                    // item.material.uniforms.uEmissiveTexture.value= this[this.canFrames[this.coverIndex]['key']];
                }
            });
        })
        // cubeFolder.add(cube.rotation, 'x', 0, Math.PI * 2)

        // Getting the event dispatcher instance
        this.events = EventDispatcher.getObj();
        this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("CAMERA_EVENTS", this.onEvents.bind(this));


        document.querySelectorAll(".desc3").forEach((descBtn) => {
            descBtn.addEventListener("click", this.showIngredients.bind(this))
        });
        document.querySelectorAll(".ingredients_popup .close").forEach((descBtn) => {
            descBtn.addEventListener("click", this.hideIngredients.bind(this))
        });

    }
   
    hideIngredients(){
        this.ingTO && clearTimeout(this.ingTO);
        document.querySelector(".ingredients_popup").classList.remove('active2');
        this.ingTO= setTimeout(() => {
            document.querySelector(".ingredients_popup").classList.remove('active');
        }, 500)
    }
    showIngredients(){
        this.ingTO && clearTimeout(this.ingTO);
        document.querySelector(".ingredients_popup").classList.add('active');
        this.ingTO= setTimeout(() => {
            document.querySelector(".ingredients_popup").classList.add('active2');
        }, 200);
    }
    onResize(data){
        _scrollTop=$(".footer_sec").offset().top;
        this.sizes= data['sizes'];
    }
    onEvents(data){
        switch(data['message']['event_type']){
            case "create_scene":
                this.addCan();
                break;
            case "reveal_new_can":
                this.revealNewCan(0);
                break;
            case "resize":
                this.onResize(data['message']['data']);
                break;
            case "update":
                this.update(data['message']['data']);
                break;
            case "page_scroll":
                this.rotateCan(data['message']['data']);
                break;
        }
    }
    idle(delay=0){
        // console.log("idle Can")
        this.idleTwn && this.idleTwn.kill();
        this.idleTwn2 && this.idleTwn2.kill();

        this.idleTwn= gsap.to(this.canModel.rotation, {
            duration: 5,
            delay: delay,
            y: `+=.25`,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
        this.idleTwn2= gsap.to(this.canModel2.rotation, {
            duration: 5,
            delay: delay,
            y: `+=.25`,
            ease: Power1.easeInOut,
            yoyo: true,
            repeat:-1
        });
    }
    verticalMove(){
        gsap.to(this.canModel2.position, {
            duration: 4,
            delay: 0,
            y: `+=0.05`,
            ease: Cubic.easeInOut,
            yoyo: true,
            repeat:-1
        });
        gsap.to(this.canModel.position, {
            duration: 4,
            delay: 0,
            y: `+=0.05`,
            ease: Cubic.easeInOut,
            yoyo: true,
            repeat:-1
        });
    }
    rotateCan(rotateY){

        this.tiltZTwn1 && this.tiltZTwn1.kill();
        this.tiltZTwn2 && this.tiltZTwn2.kill();

        this.idleTwn && this.idleTwn.kill();
        this.idleTwn2 && this.idleTwn.kill();
        this.canTwn && this.canTwn.kill();
        this.canTwn2 && this.canTwn2.kill();

        this.canTwn= gsap.to(this.canModel.rotation, {
            duration: smoothScrollTime,//Last Stable: 0.1,
            delay: 0,
            y: rotateY,
            z: Math.PI * .24,
            ease: Cubic.easeOut,
            onUpdate: this.checkToUpdateCover.bind(this),
            onComplete: function () {
               /*  this.canTwn && this.canTwn.kill();
                this.canTwn2 && this.canTwn2.kill();
         */
                this.idle(500);
            }.bind(this)
        });
        this.canTwn2= gsap.to(this.canModel2.rotation, {
            duration: smoothScrollTime,//Last Stable: 0.1,
            delay: 0,
            y: rotateY,
            z: Math.PI * .24,
            ease: Cubic.easeOut/* ,
            onUpdate: this.checkToUpdateCover.bind(this),
            onComplete: function () {
                this.idle();
            }.bind(this) */
        });

        
        // console.log(this.canModel.rotation.y, 'this.canModel')
    }
    checkToUpdateCover(){
        // console.log('checkToUpdateCover',this.canModel.rotation.y)
        this.canModel2.rotation.set(...this.canModel.rotation)
        // this.canModel.rotation.z= -3.14*.2-Math.cos(this.canModel.rotation.y)*3.14*.2;
        // this.canModel.rotation.x= -Math.sin(this.canModel.rotation.y)*0.4;
        // this.canModel.rotation.x= -Math.sin(this.canModel.rotation.y)*0.5
        // console.log(this.canModel.rotation.y,'this.canModel.rotation.y')
        // this.canModel.rotation.z=(this.canModel.rotation.y%3.14/2)*.2;
        this.canFrames.forEach((obj, index) => {
            if(
                this.canModel.rotation.y > obj['range']['min']
                && this.canModel.rotation.y < obj['range']['max']
                && this.coverIndex != index
            ){
                
                this.coverIndex= index;
                this.updateTexture();
            }
            
            if(this.canModel.rotation.y > obj['range']['min']+ Math.PI*.75
            && this.canModel.rotation.y < obj['range']['max']- Math.PI*.75){
                this.events.dispatchEvent({
                    type: "GAMESCENE_EVENTS",
                    message: {
                        "event_type": "update_can_info",
                        data: {
                            'coverIndex': this.coverIndex
                        }
                    }
                });
                
            }
        })
    }
    update(data){
        this.delta= data['delta'];
        if(this.canShowGlitch){
            this.events.dispatchEvent({
                type: "GAMESCENE_EVENTS",
                message: {
                    "event_type": "apply_glitch",
                    data: {}
                }
            });
        }
        
    }
    updateTexture(){
        this.canModel.traverse((item) => {
            if(item.material && item.material.name.indexOf('mat_grphic') !== -1){
                // console.log(this.canFrames[this.coverIndex]['key'],"this.canFrames[this.coverIndex]['key']")
                item.material.map= this[this.canFrames[this.coverIndex]['key']];
                // item.material.aoMap= this[this.canFrames[this.coverIndex]['key']];
                // item.material.emissiveMap= this[this.canFrames[this.coverIndex]['key']];
                // item.material.metalnessMap= this[this.canFrames[this.coverIndex]['key']];
                // item.material.aoMapIntensity = 0.2;
                // item.material.envMapIntensity=10;
                // item.material.uniforms.uTexture.value= this[this.canFrames[this.coverIndex]['key']];
                // item.material.uniforms.uEmissiveTexture.value= this[this.canFrames[this.coverIndex]['key']];
            }
        });
    }
    interpolateToRange7to10(x) {
        // Calculate the proportion of x within the original range (0-10)
        var p = x / 10;
        
        // Calculate the new value within the range 7-10
        var newValue = p * 3 + 7;
        
        return newValue;
    }
 
    onWheelScroll(event){
        event.preventDefault();
        event.stopImmediatePropagation();
     
            scrollTotal =window.scrollY;//+= event.deltaY;
            scrollTotal= Math.max(0, scrollTotal);
            let actualScroll= scrollTotal;
            // scrollTotal= Math.min(8000/* +(document.querySelector('.footer_sec').clientHeight) */, scrollTotal);//9000
            scrollTotal= Math.min(6000/* +(document.querySelector('.footer_sec').clientHeight) */, scrollTotal);//9000
            
            if(scrollTotal>=0 && scrollTotal<=1000){
                document.querySelector(".banner_sec .sec>.title").classList.add("active")
                text1Twn && text1Twn.kill();
                text2Twn && text2Twn.kill();

                text1Twn= gsap.to(".banner_sec .title_row:nth-child(1)", {
                    duration: smoothScrollTime,
                    y: -scrollTotal*(.0007 *.8)*window.innerHeight,
                    ease: Cubic.easeOut
                });
                text2Twn= gsap.to(".banner_sec .title_row:nth-child(2)", {
                    duration: smoothScrollTime,
                    y: -scrollTotal*((this.sizes.width>this.sizes.height?.0009:.00075) *.8)*window.innerHeight,
                    ease: Cubic.easeOut
                });
                initYRotate && initYRotate.kill();
                initYRotate2 && initYRotate2.kill();
                this.canTwn && this.canTwn.kill();
                this.canTwn2 && this.canTwn2.kill();

                
                this.idleTwn && this.idleTwn.kill();
                this.idleTwn2 && this.idleTwn2.kill();
                // this.revealNewCan(10);

                this.tiltZTwn1 && this.tiltZTwn1.kill();
                this.tiltZTwn2 && this.tiltZTwn2.kill();

                initYRotate= gsap.to(this.canModel.rotation, {
                    duration: smoothScrollTime,//Last Stable:0.1,
                    delay: 0,
                    z:0,
                    y: `${scrollTotal/1000*Math.PI*2}`,
                    ease: Cubic.easeOut,
                    yoyo: false
                });
                
                initYRotate2= gsap.to(this.canModel2.rotation, {
                    duration: smoothScrollTime,//Last Stable:0.1,
                    delay: 0,
                    z:0,
                    y: `${-Math.PI/6+scrollTotal/1000*Math.PI*2}`,
                    ease: Cubic.easeOut,
                    yoyo: false
                });
               
            }
            if(scrollTotal>=1000 && scrollTotal<=(2500-1000)){
                // gsap.killTweensOf(this.canModel.rotation);
                // gsap.killTweensOf(this.canModel2.rotation);
                
                
                /* this.events.dispatchEvent({
                    type: "GAMESCENE_EVENTS",
                    message: {
                        "event_type": "hide_all",
                        data: {
                        }
                    }
                }); */
                /* if(this.xLogoShown){
                    this.bottleRevealed= false;
                    this.xLogoShown= false;
                    this.events.dispatchEvent({
                        type: "GAMESCENE_EVENTS",
                        message: {
                            "event_type": "remove_x_logo",
                            data: {
                            }
                        }
                    });
                } */
                if(this.bottleRevealed){
                    this.bottleRevealed= false;
                    
                    this.revealGlitchTO && clearTimeout(this.revealGlitchTO);
                    this.revealGlitchTO= setTimeout(() => {
                        this.canShowGlitch=true;

                       /*  this.events.dispatchEvent({
                            type: "GAMESCENE_EVENTS",
                            message: {
                                "event_type": "hide_all",
                                data: {
                                }
                            }
                        }); */
                        this.events.dispatchEvent({
                            type: "GAMESCENE_EVENTS",
                            message: {
                                "event_type": "start_glitch",
                                data: {}
                            }
                        });
                        
                        this.revealGlitchTO= setTimeout(() => {
                            this.revealNewCan(10);
                            this.revealGlitchTO && clearTimeout(this.revealGlitchTO);
                            this.canShowGlitch=false;
                            this.events.dispatchEvent({
                                type: "GAMESCENE_EVENTS",
                                message: {
                                    "event_type": "stop_glitch",
                                    data: {}
                                }
                            });
                            this.xLogoShown= true;
                            this.events.dispatchEvent({
                                type: "GAMESCENE_EVENTS",
                                message: {
                                    "event_type": "remove_x_logo",
                                    data: {
                                    }
                                }
                            });
                            this.events.dispatchEvent({
                                type: "GAMESCENE_EVENTS",
                                message: {
                                    "event_type": "hide_all",
                                    data: {
                                    }
                                }
                            });
                        }, 250); 
                    }, 0);
                }
                /* if(scrollTotal>2000 && !this.bottleRevealed){
                    this.bottleRevealed= true;
                    setTimeout(() => {
                        this.canShowGlitch=true;
                        this.events.dispatchEvent({
                            type: "GAMESCENE_EVENTS",
                            message: {
                                "event_type": "start_glitch",
                                data: {}
                            }
                        });
                        setTimeout(() => {
                            this.canShowGlitch=false;
                            this.events.dispatchEvent({
                                type: "GAMESCENE_EVENTS",
                                message: {
                                    "event_type": "stop_glitch",
                                    data: {}
                                }
                            });
                        }, 250); 
                    }, 0);
                   
                } */
                // if(!this.bottleRevealed){
                    // this.revealNewCan(this.interpolateToRange7to10((2500-scrollTotal)/100));
                // }else{
                    // this.revealNewCan(0);
                // }
                if(scrollTotal<=(1750)){
                    this.bottleRevealed= false;
                }
            }
            if(scrollTotal>=(2500-1000) && scrollTotal<=(3000-1000)){
                if(!this.bottleRevealed){
                    this.bottleRevealed= true;
                    // this.revealNewCan(0);
                    this.revealGlitchTO && clearTimeout(this.revealGlitchTO);
                    this.revealGlitchTO= setTimeout(() => {
                        this.canShowGlitch=true;
                        this.events.dispatchEvent({
                            type: "GAMESCENE_EVENTS",
                            message: {
                                "event_type": "start_glitch",
                                data: {}
                            }
                        });
                        document.querySelector(".banner_sec .sec>.title").classList.remove("active")
                        this.revealGlitchTO= setTimeout(() => {
                            this.revealNewCan(0);
                            this.revealGlitchTO && clearTimeout(this.revealGlitchTO);
                            this.canShowGlitch=false;
                            this.events.dispatchEvent({
                                type: "GAMESCENE_EVENTS",
                                message: {
                                    "event_type": "stop_glitch",
                                    data: {}
                                }
                            });
                            this.xLogoShown= true;
                            this.events.dispatchEvent({
                                type: "GAMESCENE_EVENTS",
                                message: {
                                    "event_type": "add_x_logo",
                                    data: {
                                    }
                                }
                            });
                        }, 250); 
                    }, 0);
                }
                /* if(!this.xLogoShown){
                    this.xLogoShown= true;
                    this.events.dispatchEvent({
                        type: "GAMESCENE_EVENTS",
                        message: {
                            "event_type": "add_x_logo",
                            data: {
                            }
                        }
                    });
                } */
                if(isMobile){
                    this.events.dispatchEvent({
                        type: "CAMERA_EVENTS",
                        message: {
                            "event_type": "zoom_on_tilt",
                            data: {
                                'zoomLevel': (Math.PI * .24-((3000-1000)-scrollTotal)/500*Math.PI * .24)/(Math.PI * .24)
                            }
                        }
                    });
                }
                this.tiltToNewAngle(Math.PI * .24-((3000-1000)-scrollTotal)/500*Math.PI * .24);
            }
            if(scrollTotal>=(3000-1000) && scrollTotal<=(9000-1000)){
                if(this.canShowGlitch && scrollTotal>= (4000-1000)){
                    this.canShowGlitch= false;
                    this.events.dispatchEvent({
                        type: "GAMESCENE_EVENTS",
                        message: {
                            "event_type": "stop_glitch",
                            data: {}
                        }
                    });
                }
                // console.log(Math.PI * .24-((3000-1000)-scrollTotal)/500*Math.PI * .24,' Tilt Angle')
      
                // this.tiltToNewAngle(Math.PI * .24-(3000-scrollTotal)/500*Math.PI * .24);
                this.rotateCan(Math.PI*2+(scrollTotal-(3000-1000))/(2000-1000)*Math.PI*2)
            }
            // return false;
            // console.log(scrollTotal,'scrollTotalscrollTotal')

            // if(this.scrollDisabled){
            //     console.log(actualScroll,':',_scrollTop-80)
            // }
            if(actualScroll<_scrollTop-80/* 5950 */ && this.scrollDisabled){
                // console.log("HIDE FORM")
                this.disableWheel();
               
                window.scrollTo({top: 6000, behavior: 'instant'});
                this.events.dispatchEvent({
                    type: "GAMESCENE_EVENTS",
                    message: {
                        "event_type": "start_glitch",
                        data: {}
                    }
                });
                setTimeout(() => {
                    this.events.dispatchEvent({
                        type: "GAMESCENE_EVENTS",
                        message: {
                            "event_type": "stop_glitch",
                            data: {}
                        }
                    });
                    
                    setTimeout(() => {
                        this.scrollDisabled= false;
                        
                        document.querySelector(".footer_sec").classList.remove('active2');
                        setTimeout(()=>{
                            this.enableWheel();
                        }, 500)
                    }, 0);
                   }, 250);
                // document.querySelector(".footer_sec").classList.remove('active2');
            }
            if(scrollTotal == 6000 && !this.scrollDisabled){
                this.scrollDisabled= true;
               
                // document.querySelector("body").classList.remove("scrollable");
                this.disableWheel();
                
                this.events.dispatchEvent({
                    type: "GAMESCENE_EVENTS",
                    message: {
                        "event_type": "start_glitch",
                        data: {}
                    }
                });
               
               setTimeout(() => {
                this.events.dispatchEvent({
                    type: "GAMESCENE_EVENTS",
                    message: {
                        "event_type": "stop_glitch",
                        data: {}
                    }
                });
                
                setTimeout(() => {
                    window.scrollTo({top: _scrollTop-80, behavior: 'instant'});
                    document.querySelector(".footer_sec").classList.add('active2');
                    setTimeout(()=>{
                        this.enableWheel();
                    }, 500)
                }, 0);
               }, 250);
               
            }
           
    }
    scrollToAndWait(targetPosition, callback) {
        var interval = 100; // Adjust as needed
        var previousScrollPosition = window.scrollY;
    
        window.scrollTo({
            top: _scrollTop,
            behavior: 'smooth'
        });
    
        var checkScrollInterval = setInterval(function() {
            var currentScrollPosition = window.scrollY;
            if (currentScrollPosition === previousScrollPosition) {
                clearInterval(checkScrollInterval);
                if (callback && typeof callback === 'function') {
                    callback();
                }
            } else {
                previousScrollPosition = currentScrollPosition;
            }
        }, interval);
    }
    revealRegister(revealAmount){
        this.registerRevealTwn && this.registerRevealTwn.kill();
        this.registerRevealTwn= gsap.to('.home_registration', {
            duration: .25,
            opacity: revealAmount,
            ease: Cubic.easeOut,
        });
    }
    onRotateBasedOnWheel(){
        if(canRevealNew && !canRevealInProgress){
            canRevealInProgress= true;
            this.revealNewCan();
        }
        // this.idle();
    }
    disableWheel(){
        window.removeEventListener("scroll", this.onWheelScroll);
        document.querySelector("body").classList.remove("scrollable");
    }
    enableWheel(){
        document.querySelector("body").classList.add("scrollable");
        window.addEventListener("scroll", this.onWheelScroll)
       
    }
    onTouchStart(event){
        startY = event.touches[0].clientY; 
    }
    onTouchMove(event){
        const deltaY = event.touches[0].clientY - startY; // Calculate the vertical distance moved
        this.onWheelScroll({
            'deltaY': -deltaY
        }) // Scroll the window by the opposite direction of finger movement
        startY = event.touches[0].clientY; // Update the initial touch position
        event.preventDefault(); // Prevent default touch behavior (like page bounce)
    }
    enableScroll(){
        document.querySelector("header").classList.add("active2");

      /*   document.querySelector("header .center").addEventListener("click", () => {
            location.reload();
        }); */
        
        document.querySelector("#show_register").addEventListener("click", () => {
            $('html').animate({
                scrollTop: $(".footer_sec").offset().top
            }, 500, "swing"); // Adjust the duration as needed (in
        })
        document.querySelector("header #house_of_max").addEventListener("click", () => {
            $('html').animate({
                scrollTop: $(".footer_sec").offset().top
            }, 500, "swing"); // Adjust the duration as needed (in
        })
    }
    popupCan(){
       /*  this.events.dispatchEvent({
            type: "GAMESCENE_EVENTS",
            message: {
                "event_type": "add_x_logo",
                data: {
                }
            }
        });
        this.events.dispatchEvent({
            type: "GAMESCENE_EVENTS",
            message: {
                "event_type": "show_can_info_red",
                data: {
                }
            }
        }); */


        /* 
          this.canModel.position.z= -1;
        this.canModel2.position.z= -1;
        */
        //   this.canModel.position.y= -.1;
        this.animateBanner();
        let popUpProgress={
            'value': 0,
        }
        // 
        let initIdlePlayed=false;
        // this.verticalMove();
       this.popTwn1= gsap.to(this.canModel.position, {
            duration: 1*1.5,
            delay: 0.2,
            z: 0.1,
            ease: Cubic.easeOut,
            onComplete: function () {
                this.idle();
                if(window.location.hash == '#register'){
                    window.scrollTo({top: $(".footer_sec").offset().top, behavior: 'smooth'});
                    setTimeout(() => {
                        this.events.dispatchEvent({
                            type: "GAMESCENE_EVENTS",
                            message: {
                                "event_type": "hide_all",
                                data: {
                                    'byForce': true,
                                    'lastCanKey': 'red'
                                }
                            }
                        });
                        this.events.dispatchEvent({
                            type: "GAMESCENE_EVENTS",
                            message: {
                                "event_type": "hide_all",
                                data: {
                                    'byForce': true,
                                    'lastCanKey': 'blue'
                                }
                            }
                        });
                        this.events.dispatchEvent({
                            type: "GAMESCENE_EVENTS",
                            message: {
                                "event_type": "hide_all",
                                data: {
                                    'byForce': true,
                                    'lastCanKey': 'green'
                                }
                            }
                        });
                        let scrollTotal =window.scrollY;//+= event.deltaY;
                        scrollTotal= Math.max(0, scrollTotal);
                        scrollTotal= Math.min(5000/* +(document.querySelector('.footer_sec').clientHeight) */, scrollTotal);//9000
                        text1Twn= gsap.to(".banner_sec .title_row:nth-child(1)", {
                            duration: 0,
                            y: -scrollTotal*(.0007 *.8)*window.innerHeight,
                            ease: Cubic.easeOut
                        });
                        text2Twn= gsap.to(".banner_sec .title_row:nth-child(2)", {
                            duration: 0,
                            y: -scrollTotal*((this.sizes.width>this.sizes.height?.0009:.00075) *.8)*window.innerHeight,
                            ease: Cubic.easeOut
                        });
                       /*  this.events.dispatchEvent({
                            type: "GAMESCENE_EVENTS",
                            message: {
                                "event_type": "hide_all",
                                data: {
                                    'byForce': true,
                                    'lastCanKey': 'pink'
                                }
                            }
                        }); */
                       /*  this.events.dispatchEvent({
                            type: "GAMESCENE_EVENTS",
                            message: {
                                "event_type": "hide_all",
                                data: {
                                    'byForce': true,
                                    'lastCanKey': 'red'
                                }
                            }
                        }); */
                    }, 1000)
                    // return false;
                    setTimeout(() => {
                 /*     $('html').animate({
                         scrollTop: $(".footer_sec").offset().top
                     }, 0, "linear",function(){
                       setTimeout(() => {
                        this.events.dispatchEvent({
                            type: "GAMESCENE_EVENTS",
                            message: {
                                "event_type": "hide_all",
                                data: {
                                    'byForce': true,
                                    'lastCanKey': 'red'
                                }
                            }
                        });
                        this.events.dispatchEvent({
                            type: "GAMESCENE_EVENTS",
                            message: {
                                "event_type": "hide_all",
                                data: {
                                    'byForce': true,
                                    'lastCanKey': 'blue'
                                }
                            }
                        });
                        this.events.dispatchEvent({
                            type: "GAMESCENE_EVENTS",
                            message: {
                                "event_type": "hide_all",
                                data: {
                                    'byForce': true,
                                    'lastCanKey': 'green'
                                }
                            }
                        });
                        this.events.dispatchEvent({
                            type: "GAMESCENE_EVENTS",
                            message: {
                                "event_type": "hide_all",
                                data: {
                                    'byForce': true,
                                    'lastCanKey': 'pink'
                                }
                            }
                        });
                       }, 0);

                    }.bind(this)); */
                    }, 1000)
                 }else{
                    window.scrollTo({
                        'top':0
                    })
                 }
               
                setTimeout(this.enableScroll.bind(this),100)
                this.verticalMove();
            
                this.enableWheel();
              
            }.bind(this)
        });
        gsap.to(this.canModel2.position, {
            duration: 1*1.5,
            delay: 0.2,
            z: 0.1,
            ease: Cubic.easeOut,
            onComplete: function () {
        
            }.bind(this)
        });
        
        gsap.to(this.canModel.rotation, {
            duration: 1*1.5,
            delay: 0.2,
            y: 0,
            ease: Cubic.easeOut,
            onComplete: function () {
                // this.idle();
            }.bind(this)
        });
        gsap.to(this.canModel2.rotation, {
            duration: 1*1.5,
            delay: 0.2,
            y: -Math.PI/6,
            ease: Cubic.easeOut,
            onComplete: function () {
                // this.idle();
            }.bind(this)
        });
        gsap.to(popUpProgress, {
            duration: 2*1.5,
            delay: 0.2,
            value: 1,
            ease: Elastic.easeOut,
            onUpdate: function(){
                this.canModel.position.y = -1.2+1.1*popUpProgress['value'];
                this.canModel2.position.y = -1.2+1.1*popUpProgress['value']
            }.bind(this),
            onComplete: function () {
                // this.idle();
            }.bind(this)
        });
        //this.canModel
    }
    onCanRevealed(){
        if(canRevealInProgress && !canRevealed){
            canRevealInProgress= false;
            canRevealed= true;
            this.tiltToNewAngle();
            this.idle();
        }
    
    }
    animateBanner(){
        document.querySelectorAll('.letter').forEach((letter) => {
            letter.classList.add('active');
        })
        this.stagg= gsap.from(".letter", {
            y: 30, // move up by 100 pixels
            // opacity: 0, // fade in
            stagger: 0.03, // stagger the animation
            duration: 0.5*1.5, // duration of the animation,
            ease: Back.easeInOut,
            yoyo: true,
            delay:0.65,
            onComplete: function(){
                gsap.to(".letter", {
                    y: 5, // move up by 100 pixels
                    // opacity: 0, // fade in
                    stagger: 0.1, // stagger the animation
                    duration: 1, // duration of the animation,
                    repeat:-1,
                    yoyo:true,
                    delay:.2/* ,
                    ease: Back.easeInOut */
                  });
            }.bind(this)
          });
          this.stagg= gsap.from(".letter", {
            opacity: 0, // fade in
            stagger: 0.03, // stagger the animation
            duration: 0.5*1.5, // duration of the animation,
            ease: Cubic.easeIn,
            delay:0.65/* ,
            yoyo: false,
            onComplete: this.bannerSlowBlink.bind(this) */
          })
        //   this.bannerSlowBlink();
          
    }
    bannerSlowBlink(){
        this.stagg= gsap.to(".letter", {
            opacity: 0.75, // fade in
            stagger: 0.03, // stagger the animation
            duration: 1, // duration of the animation,
            ease: Cubic.easeIn,
            delay:0.65,
            yoyo: true,
            repeatDelay:1,
            repeat: -1
          })
    }
    tiltToNewAngle(angle){
        // alert("S")
       /*  this.canTwn && this.canTwn.kill();
        this.canTwn2 && this.canTwn2.kill(); */

        this.tiltZTwn1 && this.tiltZTwn1.kill();
        this.tiltZTwn2 && this.tiltZTwn2.kill();
        
       this.tiltZTwn1= gsap.to(this.canModel.rotation, {
            duration: smoothScrollTime,//Last Stable:0.1,
            delay: 0,
            z: angle/* Math.PI * .24 */,
            ease: Cubic.easeOut,
            onComplete: function () {
              /*   this.idleTO && clearTimeout(this.idleTO);
                this.idleTO= setTimeout(()=>{
                    this.idle(500);
                },1000) */
            }.bind(this)
        });
        this.tiltZTwn2= gsap.to(this.canModel2.rotation, {
            duration: smoothScrollTime,//Last Stable:0.1,
            delay: 0,
            z: angle, 
            ease: Cubic.easeOut,
            onComplete: function () {
                
            }.bind(this)
        });
       
    }
    revealNewCan(revelAmount){
        

        this.revealTwn && this.revealTwn.kill();

        this.tiltZTwn1 && this.tiltZTwn1.kill();
        this.tiltZTwn2 && this.tiltZTwn2.kill();
        // this.oldRealisticCover= item;
        if(revelAmount==0){
            this.canModel2.visible=false;
            this.canModel.visible=true;
            this.coverIndex=0;
            this.updateTexture();
            this.coverIndex=-1;
            
        }else{
            this.canModel2.visible=true;
            this.canModel.visible=false;
        }
       /*  this.revealTwn= gsap.to(this.canMaterial.uniforms.uTime, {
            duration: 0,//revelAmount==10?0:0.75,
            delay: 0,
            value: revelAmount,
            ease: Power1.easeOut
        }); */
        this.tiltZTwn1= gsap.to(this.canModel.rotation, {
            duration: smoothScrollTime,
            delay: 0,
            z: 0,
            y: `${Math.PI*2}`,
            ease: Cubic.easeOut,
            onComplete: function () {
                this.events.dispatchEvent({
                    type: "GAMESCENE_EVENTS",
                    message: {
                        "event_type": "reset_can_index",
                        data: {
                        }
                    }
                });
            }.bind(this)
        });
        this.tiltZTwn2= gsap.to(this.canModel2.rotation, {
            duration: smoothScrollTime,
            delay: 0,
            z: 0,
            y: `${-Math.PI/6+Math.PI*2}`,
            ease: Cubic.easeOut,
            onComplete: function () {
                
            }.bind(this)
        });
        
        
    }
   
    addCan(){
        _scrollTop=$(".footer_sec").offset().top;

        this.smoothNoise= Global.assets['textures']['SMOOTH_NOISE']['asset']
        this.smoothNoise2= Global.assets['textures']['SMOOTH_NOISE2']['asset']
        this.redCover= Global.assets['textures']['COVER_RED']['asset']
        this.defaultCover= Global.assets['textures']['COVER_DEFAULT']['asset']
        this.blueCover= Global.assets['textures']['COVER_BLUE']['asset']
        this.greenCover= Global.assets['textures']['COVER_GREEN']['asset']
        this.pinkCover= Global.assets['textures']['COVER_PINK']['asset']
  

        this.defaultCover.colorSpace= THREE.SRGBColorSpace;
        this.redCover.colorSpace= THREE.SRGBColorSpace;
        this.blueCover.colorSpace= THREE.SRGBColorSpace;
        this.greenCover.colorSpace= THREE.SRGBColorSpace;
        this.pinkCover.colorSpace= THREE.SRGBColorSpace;

        // Global.assets['cubemap']['0']['asset'].flipY= true;//THREE.SRGBColorSpace;

        this.canModel2 = Global.assets['glbs']['can']['asset'].scene.clone();
        this.canModel2.scale.set(10, 10, 10);
        this.scene.add(this.canModel2);


        // this.canModel2.rotation.y= -Math.PI/8;
        // this.canModel.rotation.z = Math.PI * .24;
      
       
       

        this.canModel = Global.assets['glbs']['can']['asset'].scene.clone();
        this.canModel.scale.set(10, 10, 10);
        this.scene.add(this.canModel);


        this.canModel.position.y= -2;
        this.canModel2.position.y= -2;

        this.nextDef= this.canModel.rotation.y;

  
        this.canModel.visible=false;
        this.canModel.position.z= 2;
        this.canModel2.position.z= 2;
        this.canModel.rotation.y= Math.PI * 3;
        this.canModel2.rotation.y= Math.PI * 3;
       

        // const light = new THREE.PointLight('#ffffff', 1); // soft white light//0x404040
        // light.position.set(1,1,1)
        // this.scene.add(light);
        this.canModel.traverse((item) => {
            
          
            if(item.material && item.material.name.indexOf('mat_grphic') !== -1){
                
                item.material.map= this.redCover;
      
                item.material.envMapIntensity = 4;
                item.material.envMap=Global.assets['cubemap']['0']['asset'];


                item.material.metalness= 0.6;//0.8;
                item.material.roughness=0.15*1.25;//0.15;
                item.material.transparent=false;
                item.material.name="mat_grphic1";

            }
            else if(item.material && item.material.name.indexOf('mat_grphic') == -1){
                item.material.envMap= Global.assets['cubemap']['0']['asset'];
                item.material.envMapIntensity = 3.0;
                item.material.metalness= 0.8;
                item.material.roughness=0.15;
            }
        });
        // this.canModel.visible=false;
        // this.canModel.x+=1;
        let can2Material= new THREE.MeshStandardMaterial();
        this.canModel2.traverse((item) => {
            
          
            if(item.material && item.material.name.indexOf('mat_grphic') !== -1){
                item.material=can2Material;
                item.material.map= this.defaultCover;
      
                item.material.envMapIntensity = 7;
                item.material.envMap=Global.assets['cubemap']['0']['asset'];
       
                item.material.metalness= 0.6;//0.8;
                item.material.roughness=0.25;//0.15;
                item.material.transparent=false;
                item.material.name="mat_grphic1";

            }
            else if(item.material && item.material.name.indexOf('mat_grphic') == -1){
                item.material.envMap= Global.assets['cubemap']['0']['asset'];
                item.material.envMapIntensity = 3.0;
                item.material.metalness= 0.8;
                item.material.roughness=0.15;
            }
        });

        /*this.canModel2.traverse((item) => {
            item.material && (item.material.side= THREE.DoubleSide);
            if(item.material && item.material.name.indexOf('mat_grphic') !== -1){
       
               
                item.scale.add(new THREE.Vector3(.0,.01,.0))
                this.oldRealisticCover= item;
                
                item.material=new CustomShaderMaterial({
                    // CSM
                    baseMaterial: THREE.MeshStandardMaterial,
                    metalness: 0.6,
                    roughness: 0.25,
                    vertexShader: canVertexShader,
                    fragmentShader: canFragmentShader,
                    transparent: true,
              
                    uniforms:{
                        'uCameraPosition': { value: new THREE.Vector3(...this.camera.position)},
                        'uRepeat': { value: 1 },
                        'uNoise': { value: this.smoothNoise },
                        'uNoise2': { value: this.smoothNoise2 },
                        'uTexture': { value: this.defaultCover },
                        'uEmissiveTexture': { value: this.defaultCover },
                        'uEmissiveIntensity': { value: 1 },
                        'uTime': { value: 10 },
                        'uColor': {value: new THREE.Vector3(1, 1, 1)}
                    }
                });
                this.canMaterial= item.material;
                item.material.envMap =Global.assets['cubemap']['0']['asset'];
                item.material.envMapIntensity = 6;//4.;
                this.oldCoverMat= item.material;
            }else if(item.material){
                item.material.map= this.redCover;
                
                item.material.envMap= Global.assets['cubemap']['0']['asset'];
                item.material.envMapIntensity = 1.0;
                item.material.metalness= 0.7;
                item.material.roughness=0.2;
            }
            
        });*/
        // setTimeout(() => {
        //     this.revealNewCan();
        // }, 2000)


        // this.updateTexture();
        // this.idle();


      
        this.popupCan(); 
        // this.revealNewCan();
    }
    
}